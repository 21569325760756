<div class="relative z-10 h-screen" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity "></div>
    <div class="fixed inset-0 z-10 overflow-y-auto ">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div
                class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm">
                <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div class=" w-full flex flex-wrap" *ngIf="cardByIdDetails">
                        <div class="block  w-full">
                            <div class=" w-full px-4 py-4  bg-white rounded-lg border shadow-sm ">
                                <div class="flex justify-between items-center w-full">
                                    <div>
                                        <figure>
                                            <img [src]="fileService.setDefaultImage(cardByIdDetails.companyLogo,'company')"
                                                alt="photo" class="rounded-full w-[65px] h-[65px]" />

                                        </figure>
                                    </div>
                                </div>
                                <div class="relative w-full ">
                                    <figure class="left-20 right-20 absolute">
                                        <img [src]="fileService.setDefaultImage(cardByIdDetails.profilePhoto,'user',150)"
                                            alt="photo" class="rounded-md" />
                                    </figure>
                                </div>
                                <div class="rounded-md p-4 text-center w-full mt-28 pt-16"
                                    [ngStyle]="{'background-color':  cardByIdDetails.color }"
                                    [ngClass]="'bg-[' + cardByIdDetails.color + ']'">
                                    <div *ngFor="let item of personalInfo">
                                        <!-- <div *ngIf="item.language==Lang"> -->
                                        <h3 class="text-white text-base font-semibold">
                                            {{item.firstName}}{{item.lastName}} </h3>
                                        <p class="text-white/80 text-sm">{{item.jobTitle}}</p>
                                        <p class="text-white/80 text-sm">{{item.company}}</p>
                                        <!-- </div> -->
                                    </div>
                                </div>
                                <p-accordion [activeIndex]="0">
                                    <p-accordionTab header="MyCard link">
                                        <div class="mx-auto flex justify-center">
                                            <button class="border border-gray-300 rounded-md px-4 py-2 flex "
                                                (click)="copy(this.qrCodeValue)"><span class="mr-3"><svg width="18"
                                                        height="22" viewBox="0 0 18 22" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M12.1578 3.84764H1.9305C0.970845 3.84764 0.191406 4.62708 0.191406 5.58674V20.2609C0.191406 21.2206 0.970845 22 1.9305 22H12.1578C13.1175 22 13.8969 21.2206 13.8969 20.2609V5.58674C13.8924 4.62708 13.113 3.84764 12.1578 3.84764ZM12.6759 20.2564C12.6759 20.5448 12.4417 20.779 12.1533 20.779H1.926C1.63765 20.779 1.40337 20.5448 1.40337 20.2564V5.58674C1.40337 5.29839 1.63765 5.06411 1.926 5.06411H12.1533C12.4417 5.06411 12.6759 5.29839 12.6759 5.58674V20.2564Z"
                                                            fill="#0D0D0D" />
                                                        <path
                                                            d="M16.068 0H5.84066C4.881 0 4.10156 0.779439 4.10156 1.73909C4.10156 2.077 4.37189 2.34733 4.7098 2.34733C5.0477 2.34733 5.31803 2.077 5.31803 1.73909C5.31803 1.45075 5.55231 1.21647 5.84066 1.21647H16.068C16.3563 1.21647 16.5906 1.45075 16.5906 1.73909V16.4133C16.5906 16.7016 16.3563 16.9359 16.068 16.9359C15.7301 16.9359 15.4597 17.2062 15.4597 17.5441C15.4597 17.882 15.7301 18.1524 16.068 18.1524C17.0276 18.1524 17.8071 17.3729 17.8071 16.4133V1.73909C17.8071 0.779439 17.0276 0 16.068 0Z"
                                                            fill="#0D0D0D" />
                                                    </svg>
                                                </span> <span>Copy Link</span></button>
                                        </div>
                                    </p-accordionTab>

                                    <p-accordionTab header="QrCode">
                                        <figure>
                                            <qrcode qrdata={{qrCodeValue}} [width]="256" [errorCorrectionLevel]="'M'">
                                            </qrcode>
                                        </figure>
                                    </p-accordionTab>

                                    <p-accordionTab header="Email">
                                        <div class="mb-3">
                                            <a class="flex text-green-900 items-center">
                                                <div class="mr-5">
                                                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M18 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H18C18.5304 16 19.0391 15.7893 19.4142 15.4142C19.7893 15.0391 20 14.5304 20 14V2C20 1.46957 19.7893 0.960859 19.4142 0.585786C19.0391 0.210714 18.5304 0 18 0ZM18 4.7L10 10.034L2 4.7V2.297L10 7.63L18 2.297V4.7Z"
                                                            fill="currentColor" [attr.fill]="cardByIdDetails.color" />
                                                    </svg>
                                                </div>
                                                <div>
                                                    <p class="text-black/80 pb-0 mb-3"><input type="text" id="title"
                                                            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900  rounded-sm block w-full p-2.5"
                                                            [(ngModel)]="myMail"></p>
                                                </div>
                                            </a>
                                            <div class="flex justify-center bg-gray-50 text-white text-center">
                                                <button class=" bg-gray-900 text-white text-center" type="submit"
                                                    (click)="mail()">Get Card
                                                </button>
                                            </div>
                                        </div>
                                    </p-accordionTab>

                                    <p-accordionTab header="SMS">
                                        <div class="mb-3" *ngFor="let item of contactInfo">
                                            <a class="flex text-green-900 items-center hover:cursor-pointer">
                                                <div class="mr-5" *ngIf="item.type=='Personal'">
                                                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M17.9924 8.999H19.9924C19.9924 3.869 16.1194 0 10.9824 0V2C15.0444 2 17.9924 4.943 17.9924 8.999Z"
                                                            fill="currentColor" [attr.fill]="cardByIdDetails.color" />
                                                        <path
                                                            d="M10.9983 5.99999C13.1013 5.99999 13.9983 6.89699 13.9983 8.99999H15.9983C15.9983 5.77499 14.2233 3.99999 10.9983 3.99999V5.99999ZM14.4203 11.443C14.2282 11.2683 13.9757 11.1752 13.7161 11.1832C13.4566 11.1912 13.2103 11.2998 13.0293 11.486L10.6363 13.947C10.0603 13.837 8.90233 13.476 7.71033 12.287C6.51833 11.094 6.15733 9.933 6.05033 9.361L8.50933 6.96699C8.69574 6.78612 8.80446 6.53982 8.81249 6.2802C8.82052 6.02059 8.72721 5.76804 8.55233 5.57599L4.85733 1.51299C4.68237 1.32035 4.43921 1.2035 4.17948 1.18725C3.91976 1.17101 3.66393 1.25665 3.46633 1.42599L1.29633 3.28699C1.12344 3.46051 1.02025 3.69145 1.00633 3.93599C0.991329 4.18599 0.705329 10.108 5.29733 14.702C9.30333 18.707 14.3213 19 15.7033 19C15.9053 19 16.0293 18.994 16.0623 18.992C16.3068 18.9783 16.5377 18.8747 16.7103 18.701L18.5703 16.53C18.7398 16.3325 18.8256 16.0768 18.8096 15.817C18.7935 15.5573 18.6768 15.3141 18.4843 15.139L14.4203 11.443Z"
                                                            fill="currentColor" [attr.fill]="cardByIdDetails.color" />
                                                    </svg>
                                                </div>
                                                <div class="mr-5" *ngIf="item.type=='Work'">
                                                    <svg width="14" height="20" viewBox="0 0 14 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M12 0H2C0.897 0 0 0.897 0 2V18C0 19.103 0.897 20 2 20H12C13.103 20 14 19.103 14 18V2C14 0.897 13.103 0 12 0ZM2 14.999V3H12L12.002 14.999H2Z"
                                                            fill="currentColor" [attr.fill]="cardByIdDetails.color" />
                                                    </svg>
                                                </div>
                                                <div>
                                                    <p class="text-black/80 pb-0">
                                                        {{item.mobile.replace('_', '-')}}</p>
                                                    <p class="text-xs text-gray-500">
                                                        {{item.type}}</p>
                                                </div>
                                            </a>
                                        </div>
                                    </p-accordionTab>
                                </p-accordion>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-[#1A1A1A] flex rounded-b-lg py-2 px-1 items-center justify-between">
                    <figure>
                        <img src="../../../../assets/img_mytag.png" alt="mytag" />
                    </figure>
                    <p class="text-[11px] text-white mr-1">SMART BUSINESS CARD</p>
                    <a href="{{'WEB_URL'|env}}" target="_blank">
                        <button class="border border-white p-1 rounded-md text-white text-xs">Get Your
                            Card</button></a>
                </div>
            </div>
        </div>
    </div>
</div>

<app-toast></app-toast>