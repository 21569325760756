import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ApiService } from "../service/api.service";
import { LangService } from '../service/lang.service';


const API_URL = `${environment.API_URL}`;
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private apiservice: ApiService, private langser: LangService) { }

  contacts() {
    return this.apiservice.Get(`${API_URL}/contactList` + '?code=' + this.langser.getLang())
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  contactsById(value: any) {
    return this.apiservice.Get(`${API_URL}/contacts` + '/' + value)
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  deleteContact(value: any) {
    return this.apiservice.Post(`${API_URL}/contacts/delete`, value)
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  saveNotes(value: any) {
    return this.apiservice.Post(`${API_URL}/conactTagAndNotes`, value)
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  cardList(value: any) {
    return this.apiservice.Get(`${API_URL}/cardProfile` + '?code=' + value + '&type=1')
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  cardProfileCount() {
    return this.apiservice.Get(`${API_URL}/cardProfileCount`)
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  cardProfile(value:any) {
    return this.apiservice.Get(`${API_URL}/cardProfilePending`+'?card_no=' + value)
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  cardDetailsByID(id: string) {
    return this.apiservice.Get(`${API_URL}/cardProfile/` + id + '?code=' + this.langser.getLang())
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  updateCardStatus(value: any) {
    return this.apiservice.Post(`${API_URL}/cardStatusChange`, value)
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }
  updateBrandingStatus(value: any) {
    return this.apiservice.Post(`${API_URL}/cardBranding`, value)
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }
  updateDefaultLanguage(value: any) {
    return this.apiservice.Post(`${API_URL}/defaultLanguage`, value)
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }
  duplicateCard(value: any) {
    return this.apiservice.Post(`${API_URL}/cardDuplication` + '?code=' + this.langser.getLang(), value)
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  deleteCard(value: any) {
    return this.apiservice.Post(`${API_URL}/cardProfile/delete`+ '?code=' + this.langser.getLang(), value)
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  exchangeContact(formData: any) {
    return this.apiservice.Post(`${API_URL}/contacts`, formData)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  getCardList(){
    return this.apiservice.Get(`${API_URL}/userCardList`  + '?code=' + this.langser.getLang())
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  generateVcfContent(name: string, phone: any[], email: any[]): string {
    const phoneEntries = phone.map(phone => `type=${phone.type}:mobile=${phone.mobile}`).join('\n');
    const emailEntries = email.map(email => `${email.email}`).join('\n');
    const vcfContent = `BEGIN:VCARD
VERSION:3.0
N:${name};;;;
FN:${name}
TEL;${phoneEntries}
EMAIL:${emailEntries}
END:VCARD`;

    return vcfContent;
  }

  downloadQRCode(qrcode: any) {
    // const svgElement = qrcode.qrcElement.nativeElement;
    // const serializer = new XMLSerializer();
    // const svgString = serializer.serializeToString(svgElement);
    // const dataUrl = 'data:image/svg+xml;base64,' + btoa(svgString);
    // const link = document.createElement('a');
    // link.download = 'qrcode.png';
    // link.href = dataUrl;
    // link.click();
  }

  getProfileData(lang: string): Observable<any> {
    var obj: { [k: string]: any } = {};

    const cardCount = this.cardProfileCount();
    obj['cardCount'] = cardCount;

    const cardProfileList = this.cardList(lang);
    obj['cardProfileList'] = cardProfileList;

    return forkJoin(obj);
  }

}
