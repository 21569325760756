export class AddressModel {
  doorNo: string = "";
  address1: string = "";
  address2: string = "";
  landmark: string = "";
  city: string = "";
  state: string = "";
  postalCode: string = "";
  country: string = "";
}
