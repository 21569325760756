import { environment } from 'src/environments/environment';
import { AddressModel } from './address.model';
import { AuthModel } from './auth.model';

export class UserModel extends AuthModel {
  id: string = "";
  email: string = "";
  countryCode: string = "IN";
  phone: string = "";
  customerID: string = "";
  groupType: number = 0;
  defaultLanguage: string = 'en';

  // personal information
  firstname: string = "";
  lastname: string = "";
  fullname: string = "";
  pic: string = "";

  // account information
  companyName: string = "";
  logo: string = "";
  address?: AddressModel;
  website: string = "";

  licenseType: string = "";
  pendingCards: number = 0;
  totalCards: number = 0;

  public setUser(user: any) {
    this.id = user.id;
    this.customerID = user.customerID;
    this.groupType = user.groupType;
    this.email = user.email || '';
    this.phone = user.phone || '';
    this.firstname = user.firstname || '';
    this.lastname = user.lastname || '';
    if (user.firstname && user.lastname) {
      this.fullname = user.firstname + ' ' + user.lastname;
    } else if (user.firstname) {
      this.fullname = user.firstname;
    } else if (user.lastname) {
      this.fullname = user.lastname;
    }
    this.defaultLanguage = (user.defaultLanguage) ? user.defaultLanguage.toLowerCase() : 'en';
    this.companyName = user.companyName || '';
    this.pic = user.pic || environment.USER_DEFAULT_PIC;
    this.logo = user.logo || environment.COMPANY_DEFAULT_PIC;
    this.address = user.address || new AddressModel();
    this.website = user.website || '';

    this.licenseType = user.licenseType || '';
    this.pendingCards = user.pendingCards || 0;
    this.totalCards = user.totalCards || 0;
  }
}
