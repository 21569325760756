import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { PhoneNumberUtil } from "google-libphonenumber";
import { countries } from "../data/country";


// Custom validator to validate phone numbers using Google libphonenumber
export function validatePhoneNumber(countryCodeControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let countryList = countries;
        const phoneNumberUtil = PhoneNumberUtil.getInstance();
        const countryRow = countryList.filter(item => item.dial_code == countryCodeControl.value);
        const countryCode =  countryRow[0]?.code;
        const phoneNumber = control.value;
        try {
            if (phoneNumber == '') {
                return { required: true };
            }
            const parsedNumber = phoneNumberUtil.parse(phoneNumber, countryCode);
            if (!phoneNumberUtil.isValidNumber(parsedNumber)) {
                return { invalidPhoneNumber: true };
            } else {
                return null;
            }
        } catch (e) {
            return { invalidPhoneNumber: true };
        }
    };
}