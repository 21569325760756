import { Injectable } from '@angular/core';
import { otherProduct, Product, order } from './model/product.model';
@Injectable({
  providedIn: 'root'
})
export class ProductService {
  otherProduct: otherProduct[] = [];
  product = new Product();
  order: order[] = [];


  constructor() { }
  setOtherProductDetails(data: otherProduct) {
    this.otherProduct.push(data);
  }

  getOtherProductDetails() {
    return this.otherProduct;
  }
  setProduct(data: Product) {
    this.product = data;
  }

  getProduct() {
    return this.product;

  }
  setOrder(data: order) {
    this.order.push(data);
  }
  getOrder() {
    return this.order;
  }
}
