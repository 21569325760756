<section class="mx-auto px-4 sm:px-6 lg:px-0 bg-white ">
    <section class="lg:container lg:mx-auto py-3 lg:px-8 lg:py-8 border-b border-gray-300">
        <section class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 lg:gap-8 py-5 gap-4">
            <div class="mb-5">
                <div class="px-5 flex items-center">
                    <figure class="mb-4 mr-10">
                        <img src={{this.cardDetail.card_back_image}} alt="card type" />
                    </figure>
                    <figure class="mb-4">
                        <img src={{this.cardDetail.card_front_image}} alt="card type" />
                    </figure>
                </div>
                <h2 class="text-2xl font-semibold">{{"PRODUCT.ABOUTPRODUCT"|translate}}</h2>
                <div class="text-gray-500" [innerHTML]="this.description"></div>
            </div>
            <div class="mb-5  ">
                <form [formGroup]="productInfo">
                    <h3 class="font-semibold text-primary  mb-3">MYTAG {{this.cardDetail.type}}</h3>
                    <h2 class="text-xl font-semibold mb-2">{{"PRODUCT.CUSTOMIZEPROFILE"|translate}}</h2>
                    <p class="mb-2"><span class="mr-2">{{this.productInfo.value.sellingPrice}}</span><span
                            class="text-gray-400 mr-2 line-through">
                            {{this.productInfo.value.maxPrice}}</span> <span class="text-primary "></span></p>
                    <p class="text-gray-500 mb-2">{{"PRODUCT.CARDTYPE"|translate}} </p>
                    <div class="flex flex-wrap items-center  mb-3">

                        <ul class="flex flex-wrap  " *ngFor="let item of this.cardDetail.details">
                            <li class="relative">
                                <input class="sr-only peer" type="radio" value="yes" name="answer"
                                    id={{item.card_type}}>
                                <label
                                    class="flex mr-2  bg-white border border-gray-500 rounded-sm  cursor-pointer  peer-checked:bg-black peer-checked:text-white  px-3 py-1"
                                    (click)="cardType(item.card_type)" for={{item.card_type}}>{{item.card_type}}</label>
                            </li>
                        </ul>

                        <p class="text-gray-500 ">{{"PRODUCT.NOTE"|translate}}</p>

                    </div>
                    <div *ngIf='chooseColor'>
                        <p class="block text-gray-500 mt-3">{{"PRODUCT.CHOOSECOLOR"|translate}}</p>

                        <div *ngFor="let item of this.cardDetail.details" class=" flex flex-row items-center mr-2 ">
                            <div *ngFor="let v of item.color_detail">
                                <div *ngIf="item.card_type=='Plastic'">
                                    <button [ngStyle]="{ 'background-color': v.color }" class="h-6 w-6 border
                                        focus:border-yellow-400 focus:ring-yellow-900 mr-2"
                                        (click)="cardDetails(v.color,v.selling_price,v.max_retail_price)"></button>
                                </div>


                            </div>
                        </div>
                        <!-- <div class=" flex items-center">
                            <div class="flex items-center mr-2 ">
                                <button class="h-6 w-6 bg-black border focus:border-yellow-400 focus:ring-yellow-900"
                                    (click)="this.productInfo.get('cardColor')?.setValue('black')"></button>
                            </div>
                            <div class="flex items-center ">
                                <button
                                    class="h-6 w-6 bg-white border focus:border-yellow-400 focus:ring-gray border border-black"
                                    (click)="this.productInfo.get('cardColor')?.setValue('white')"></button>
                            </div>

                        </div> -->
                    </div>

                    <div class="block text-gray-500 mt-3">{{"PRODUCT.CHOOSELICENCE"|translate}}
                        <div class=" flex items-center">
                            <div class="flex items-center mr-2 ">
                                <input id="license" name="license" type="radio"
                                    class="h-4 w-4 border-gray-300 text-gray-900 focus:ring-gray-900 mr-2"
                                    (click)="quantity('Individual')">
                                <label for="license" class="block  text-gray-900">
                                    {{"PRODUCT.INDIVIDUAL"|translate}}
                                </label>

                            </div>
                            <div class="flex items-center ">
                                <input id="license" name="license" type="radio"
                                    class="h-4 w-4 border-gray-300 text-gray-900 focus:ring-gray-900 mr-2"
                                    (click)="quantity('company')">
                                <label for="limb-1cense" class="block  text-gray-900">
                                    {{"PRODUCT.COMPANY"|translate}}
                                </label>
                            </div>

                        </div>

                    </div>

                    <div class=" flex items-center mt-2" *ngIf="chooseQuantity">
                        <div class="mr-3 ">
                            <label for="license" class="block  text-gray-500 mb-2">{{"COMMON.LABEL.QUANTITY" |
                                translate}}</label>
                            <select id="quantity" name="quantity"
                                class="block w-full rounded-md border border-md border-gray-500 py-1.5 px-3 bg-white text-gray-500 shadow-sm sm:max-w-xs sm:text-sm "
                                formControlName="quantity">
                                <option *ngFor="let item of options" [value]="item.value">{{item.value}}</option>
                            </select>
                        </div>

                        <div class="mr-2 ">
                            <label for="license"
                                class="block  text-gray-500 mb-2">{{"PRODUCT.COMPANY"|translate}}</label>
                            <input class="border rounded-md border-gray-500 px-3 py-1" placeholder="Company Name"
                                formControlName="companyName">
                        </div>
                    </div>

                    <div class=" flex items-center mt-3">
                        <button
                            class="flex mr-2  bg-black border border-gray-500  text-white cursor-pointer px-7 py-2 rounded-md "
                            *ngIf="customizeCard"
                            (click)="customizePopup('open',0)">{{"PRODUCT.CUSTOMIZECARD"|translate}}</button>
                        <button class="flex text-center text-white bg-primary px-7 py-2 items-center rounded-md "
                            (click)="addToCart()"><span class="mr-2">{{"PRODUCT.ADDTOCART"|translate}}</span><span><svg
                                    width="19" height="15" viewBox="0 0 19 15" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0.75 1.375H2.755L4.96438 10.7662C4.48255 10.8057 4.03472 11.0302 3.71484 11.3927C3.39495 11.7551 3.22787 12.2274 3.24865 12.7104C3.26943 13.1934 3.47645 13.6496 3.82628 13.9832C4.17611 14.3169 4.64157 14.5021 5.125 14.5H15.75C15.9158 14.5 16.0747 14.4342 16.1919 14.3169C16.3092 14.1997 16.375 14.0408 16.375 13.875C16.375 13.7092 16.3092 13.5503 16.1919 13.4331C16.0747 13.3158 15.9158 13.25 15.75 13.25H5.125C4.95924 13.25 4.80027 13.1842 4.68306 13.0669C4.56585 12.9497 4.5 12.7908 4.5 12.625C4.5 12.4592 4.56585 12.3003 4.68306 12.1831C4.80027 12.0658 4.95924 12 5.125 12H15.6763C16.0991 12.0011 16.5099 11.8587 16.8414 11.5961C17.1729 11.3335 17.4055 10.9663 17.5013 10.5544L18.8256 4.92937C18.8904 4.65412 18.892 4.36778 18.8305 4.09179C18.7689 3.8158 18.6457 3.55731 18.4701 3.33566C18.2945 3.11402 18.0711 2.93496 17.8165 2.81189C17.5619 2.68882 17.2828 2.62493 17 2.625H4.33313L3.85875 0.606875C3.82648 0.469683 3.74878 0.347426 3.63827 0.259951C3.52777 0.172476 3.39094 0.124919 3.25 0.125H0.75C0.58424 0.125 0.425269 0.190848 0.308058 0.308058C0.190848 0.425269 0.125 0.58424 0.125 0.75C0.125 0.91576 0.190848 1.07473 0.308058 1.19194C0.425269 1.30915 0.58424 1.375 0.75 1.375ZM17 3.875C17.0937 3.87679 17.1858 3.89927 17.2697 3.94085C17.3537 3.98243 17.4274 4.04206 17.4856 4.11548C17.5437 4.1889 17.585 4.27428 17.6063 4.3655C17.6276 4.45673 17.6284 4.55153 17.6088 4.64312L16.2838 10.2681C16.2521 10.4054 16.1748 10.5279 16.0644 10.6155C15.954 10.703 15.8171 10.7505 15.6763 10.75H6.245L4.625 3.875H17Z"
                                        fill="currentColor" />
                                </svg>
                            </span></button>
                    </div>
                </form>
            </div>
        </section>
    </section>
</section>

<app-toast></app-toast>

<!-- image selection for business -->
<p-dialog header="" [(visible)]="customizeDesign" [modal]="true" [style]="{ width: '50vw' }" [maximizable]="false"
    [draggable]="false">
    <div class="card flex justify-content-center">
        <div class="bg-white border rounded-lg p-5 lg:mr-3 w-full">
            <h3 class="text-xl text-gray-800 mb-2 ml-2">{{"PRODUCT.ADDCUSTOMIMAGE"|translate}}</h3>
            <div class="flex">
                <a class="bg-gray-200 p-3 text-center  rounded-lg m-2" *ngIf="uploadedCustomDesign.length == 0">
                    <p-fileUpload mode="basic" #fileUploadProfile accept="image/*" [maxFileSize]="50000000"
                        [auto]="true" [multiple]="false" styleClass="w-5 h-12 p-2 rounded-full" [customUpload]="true"
                        (uploadHandler)="onUpload($event, fileUploadProfile, 1)"></p-fileUpload>
                    <p class="text-gray-800 font-semibold">{{"PRODUCT.UPLOADIMAGE"|translate}}</p>
                    <p class="text-gray-500 text-sm">{{"COMMON.LABEL.IMAGEFORMAT"|translate}}</p>
                    <p class="text-gray-500 text-sm">
                        {{"COMMON.LABEL.IMAGESIZE"|translate}}{{appConfig.max_image_size}}MB</p>
                </a>

                <a class="bg-gray-200 p-3 text-center w-1/2 rounded-lg m-2" *ngIf="uploadedCustomDesign.length > 0">
                    <div class="mx-auto flex items-center justify-center">
                        <figure class="mr-2"><img alt="image" [src]="imgProfile" class="imageSize " /></figure>
                        <a class="mr-2" (click)="removePhoto()">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                class="w-6 h-6">
                                <path fill-rule="evenodd"
                                    d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                    clip-rule="evenodd" />
                            </svg>
                        </a>
                    </div>
                    <!-- <p class="text-gray-500">{{this.uploadedCustomDesign.name}}</p> -->
                </a>
            </div>
        </div>
    </div>
    <div class="flex justify-center">
        <button (click)="customizePopup('close',0)"
            class="flex text-center text-white bg-primary px-7 py-2 items-center rounded-md ">{{"CUSTOM.LABEL.CANCEL"|translate}}</button>
        <button (click)="customizePopup('close',1)"
            class="flex text-center text-white bg-primary px-7 py-2 items-center rounded-md ">{{"CUSTOM.LABEL.SAVE"|translate}}</button>
    </div>
</p-dialog>

<!-- user redirection to login or signup -->
<p-dialog header="" [(visible)]="userLogin" [modal]="true" [maximizable]="false" [style]="{ width: '30vw'  }"
    [draggable]="false">

    <div class="sm:flex sm:items-center sm:justify-center">

        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">


            <div class="mt-2">
                <p class="text-sm text-gray-500">{{"PRODUCT.ISREGISTERED"|translate}}</p>
            </div>
        </div>
    </div>

    <div class="px-4 py-3 sm:flex sm:flex-row sm:justify-center sm:px-6">

        <button type="button"
            class="mt-3 inline-flex w-full justify-center rounded-md border border-primary bg-red-50 px-5 py-2 text-sm font-semibold text-primary shadow-sm   sm:mt-0 sm:w-auto"
            (click)="reDirect('no')">{{"LOGIN.SIGNUP"|translate}}</button>
        <button type="button"
            class="inline-flex w-full justify-center rounded-md bg-primary px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 hover:text-white sm:ml-3 sm:w-auto"
            (click)="reDirect('yes')">{{"LOGIN.LOGIN"|translate}}</button>
    </div>


</p-dialog>