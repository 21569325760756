import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HeaderComponent, FooterComponent } from './components';
import { AccordionModule } from 'primeng/accordion';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { MenuModule } from 'primeng/menu';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const COMPONENTS = [HeaderComponent, FooterComponent]
@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    HttpClientModule,
    AccordionModule,
    ReactiveFormsModule, FormsModule, SelectButtonModule, MenuModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] },
    }),
  ],
  exports: [...COMPONENTS, AccordionModule, TranslateModule, MenuModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class SharedWebModule { }
