import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'mytag';
  isDataLoaded: boolean = false;

  constructor(private translate: TranslateService) {
  }

  async ngOnInit(): Promise<void> {
    const result = await this.translate.use('en').toPromise();
    if (result) {
      this.isDataLoaded = true;
    }
  }

}
