import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { PasswordModule } from "primeng/password";
import { InputTextModule } from "primeng/inputtext";
import { CheckboxModule } from "primeng/checkbox";
import { ToastModule } from "primeng/toast";
import { RippleModule } from "primeng/ripple";
import { AccordionModule } from "primeng/accordion";
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from "primeng/dropdown";
import { MessagesModule } from "primeng/messages";
import { QRCodeModule } from 'angularx-qrcode';
import { YouTubePlayerModule } from "@angular/youtube-player";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ImageCropperModule } from "ngx-image-cropper";
import { InputSwitchModule } from 'primeng/inputswitch';

import { AlertsComponent, LanguageComponent, SocialShareComponent, YoutubePlayerComponent, ImageCropperComponent, ToastComponent } from './components';
import { CapitalizePipe, PluralPipe, RoundPipe, TimingPipe, NumberWithCommasPipe, ContactPipe, SafeUrlPipe, EnvironmentPipe } from './pipes';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const COMPONENTS = [
  AlertsComponent,
  LanguageComponent,
  YoutubePlayerComponent,
  SocialShareComponent,
  ImageCropperComponent,
  ToastComponent
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  ContactPipe,
  SafeUrlPipe,
  EnvironmentPipe
];

@NgModule({
  declarations: [...COMPONENTS, ...PIPES],
  imports: [
    CommonModule, ReactiveFormsModule, FormsModule, HttpClientModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] },
    }),
    PasswordModule, InputTextModule, ButtonModule, CheckboxModule, ToastModule,
    RippleModule, AccordionModule, DialogModule, DropdownModule, MessagesModule, QRCodeModule, YouTubePlayerModule,
    FontAwesomeModule, ImageCropperModule, SelectButtonModule, InputSwitchModule
  ],
  exports: [CommonModule, ReactiveFormsModule, FormsModule, ButtonModule, TranslateModule, PasswordModule,
    InputTextModule, HttpClientModule, CheckboxModule, ToastModule, RippleModule, AccordionModule, DialogModule,
    DropdownModule, MessagesModule, QRCodeModule, YouTubePlayerModule, FontAwesomeModule,
    ImageCropperModule, InputSwitchModule, SelectButtonModule, ...COMPONENTS, ...PIPES],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
}
