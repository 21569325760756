import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CardViewComponent } from "./web/card/card-view/card-view.component";
import { CardshareComponent } from './web/card/cardshare/cardshare.component';
import { ProductComponent } from './web/product/product.component';

const routes: Routes = [
  {
    path: "home",
    loadChildren: () =>
      import("./web/home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "product",
    loadChildren: () =>
      import("./web/product/product.module").then((m) => m.ProductModule),
  },
  {
    path: "user",
    loadChildren: () => import("./user/user.module").then((m) => m.UserModule),
  },
  { path: ":id", component: CardViewComponent },
  { path: 'share/:id', component: CardshareComponent },
  { path: 'product/:slug', component: ProductComponent },
  { path: "", redirectTo: "home", pathMatch: "full" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabledBlocking",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
