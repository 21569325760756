import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  headers: HttpHeaders = new HttpHeaders();

  constructor(public http: HttpClient) {
    this.headers.set('content-type', 'application/json');
  }

  Get(url: string, isText = false, isPdf = false) {
    if (isText) {
      let headers = new HttpHeaders();
      headers = headers.set('content-type', 'application/json');
      return this.http.get<any>(url, { headers: headers, observe: 'response', responseType: 'text' as 'json' });
    } else if (isPdf) {
      let headers = new HttpHeaders();
      headers = headers.set('Accept', 'application/pdf');
      return this.http.get<any>(url, { headers: headers, observe: 'response' });
    } else {
      return this.http.get(url, { observe: 'response' });
    }
  }

  Post(url: string, data: any) {
    return this.http.post<any>(url, data, { observe: 'response' });
  }

  Put(url: string, data: any) {
    return this.http.put<any>(url, data);
  }

  Patch(url: string, data: any) {
    return this.http.patch<any>(url, data);
  }

  Delete(url: string, data: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: data
    };
    return this.http.delete<any>(url, httpOptions);
  }

  DeleteByID(url: string) {
    return this.http.delete<any>(url);
  }


}
