export class AuthModel {
  authToken: string = "";
  refreshToken: string = "";
  expiresIn: Date = new Date();

  setAuth(auth: any) {
    this.authToken = auth.authToken;
    this.refreshToken = auth.refreshToken;
    this.expiresIn = auth.expiresIn;
  }

  setAccessToken(value: string) {
    this.authToken = value;
  }

  setRefreshToken(value: string) {
    this.refreshToken = value;
  }

  setExpires(value: any) {
    this.expiresIn = value;
  }
}
