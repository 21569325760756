import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(private readonly meta: Meta, private readonly title: Title) { }
  initDefaultMetaInformatio() {
    this.title.setTitle('MYTAG | Digital NFC Business Card');
    this.meta.addTags([
      { name: 'description', content: 'home page with seo' },
      { name: 'author', content: 'systimanx' },
      { name: 'keyword', content: 'angular,seo' },
    ]);
  }
}
