import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { WebService } from '../../web.service';
import { ProductService } from '../product.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/auth/auth.service';
import { ConfigService } from 'src/app/service/config.service';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from 'src/app/service/lang.service';
import { ToastComponent } from 'src/app/shared/components';
import { SeoSocialShareService, SeoSocialShareData } from 'ngx-seo';



@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
  providers: [MessageService],
})
export class ProductDetailComponent implements OnInit {
  @ViewChild(ToastComponent) toastComp!: ToastComponent;
  slug: any;
  cardDetail: any;
  chooseColor: boolean = false;
  chooseQuantity: boolean = false;
  customizeCard: boolean = false;
  customizeDesign: boolean = false;
  options = [{ value: '1' }, { value: '2' }, { value: '3' }, { value: '4' }, { value: '5' }, { value: '6' }, { value: '7' }, { value: '8' }, { value: '9' }, { value: '10' }]
  productInfo: FormGroup;
  description: SafeHtml | any;
  alert = { visible: false, type: '', message: '', url: '' };

  uploadedCustomDesign: any[] = [];
  userLogin: boolean = false;

  selectedFile!: File;
  selectedFileObjectUrl: string = '';
  fileInput: any;
  imgProfile: any;

  appConfig: any = {
    max_image_size: 0
  };

  constructor(private route: ActivatedRoute, public translate: TranslateService, private router: Router, private webService: WebService,
    private productService: ProductService, public fb: FormBuilder, private sanitizer: DomSanitizer, private messageService: MessageService,
     private cookieService: CookieService, private configService: ConfigService, public langser: LangService, private authService: AuthService,
     public readonly seoSocialShareService: SeoSocialShareService) {
    this.productInfo = this.fb.group({
      cardType: new FormControl('', [Validators.required]),
      cardColor: new FormControl(''),
      licenceType: new FormControl('', [Validators.required]),
      quantity: new FormControl('1'),
      companyName: new FormControl(''),
      customImage: new FormControl(''),
      sellingPrice: new FormControl(''),
      maxPrice: new FormControl(''),
      cardImage: new FormControl('')
    });
    this.appConfig = this.configService.getAppConfig();
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      //check property exists
      if (params.hasOwnProperty('slug')) {
        this.slug = params['slug'];
      }

    });
    this.getCard();
    this.langser.getData().subscribe(value => {
      this.translate.use(this.langser.getLang());

    });
    if (this.authService.isLoggedIn()) {
      this.setCard();
      if (this.productInfo.valid) {
        this.cartAPI();
      }
    }
    else {
      this.cookieService.delete('cart', '/');
    }

  }

  getCard() {
    this.webService
      .productDetailBySlug(this.slug)
      .subscribe((result: any) => {
        if (result.status == 200) {
          this.cardDetail = result.body.data;
          this.productInfo.get("cardImage")?.setValue(this.cardDetail.card_front_image);
          this.description = this.sanitizer.bypassSecurityTrustHtml(this.cardDetail.description);
          if (this.cardDetail.type == 'Business') {
            this.customizeCard = true;
          }

          this.productService.setProduct(this.cardDetail);

          for (let i = 0; i < this.cardDetail.other_products.length; i++) {
            this.productService.setOtherProductDetails(this.cardDetail.other_products[i]);
          }

          if (this.slug != '') {
            this.setCard();
            this.addToCart();
          }

          const seoData: SeoSocialShareData = {
            title: 'MYTAG',
            description: (this.cardDetail.description==null)?'Mytag':this.cardDetail.description,
            image: this.cardDetail.card_front_image,
            // keywords: '',
            // type: '',
            // author: '',
            // section: '',
            // published: '',
            // modified: ''
          };
          this.seoSocialShareService.setData(seoData);

        } else if (result.status == 203) {

        }
      }, err => {
        console.log(err);
      });
  }

  setCard() {
    const existingCartData = this.cookieService.get('cart');
    const existingCart = existingCartData ? JSON.parse(existingCartData) : [];


    this.productInfo.get('cardType')?.setValue(existingCart.cardType)
    if (existingCart.color != undefined) {
      this.productInfo.get('cardColor')?.setValue(existingCart.cardColor);
    }
    this.productInfo.get('licenceType')?.setValue(existingCart.licenceType);
    if (existingCart.companyName != undefined) {
      this.productInfo.get('companyName')?.setValue(existingCart.companyName);
    }
    if (existingCart.quantity != undefined) {
      this.productInfo.get('quantity')?.setValue(existingCart.quantity);
    }
    if (existingCart.customImage != undefined) {
      this.productInfo.get('customImage')?.setValue(existingCart.customImage);
    }
    if (existingCart.sellingPrice != undefined) {
      this.productInfo.get('sellingPrice')?.setValue(existingCart.sellingPrice);
    }
    if (existingCart.maxPrice != undefined) {
      this.productInfo.get('maxPrice')?.setValue(existingCart.maxPrice);
    }
    console.log(existingCart);


  }

  cardType(value: any) {
    this.productInfo.get("cardType")?.setValue(value);
    this.productInfo.value.cardType = value.toLowerCase();
    if (this.productInfo.value.cardType == "plastic") {
      this.chooseColor = true;
    }
    else {
      this.chooseColor = false;
      let val = this.productService.getProduct().details;
      for (let i = 0; i < val.length; i++) {
        if (val[i].card_type == value) {
          const colordetails = val[i].color_detail[0];
          this.cardDetails(colordetails.color, colordetails.selling_price, colordetails.max_retail_price);

        }
      }
    }
  }

  quantity(value: any) {
    this.productInfo.get('licenceType')?.setValue(value);
    if (value == 'company') {
      this.chooseQuantity = true;
    }
    else {
      this.chooseQuantity = false;
    }
  }

  customizePopup(value: any, type: any) {
    if (value == 'open') {
      this.customizeDesign = true;
    }
    else {
      this.customizeDesign = false;
      if (type == 1) {
        this.toastComp.displayToastMessage('success', 'Success', 'Image selected for upload');
      }
      else {
        this.removePhoto();
        this.toastComp.displayToastMessage("error", "Error", 'Image uploaded canceled');
      }
    }
  }

  get fm(): { [key: string]: AbstractControl } {
    return this.productInfo.controls;
  }

  onUpload(event: any, fileUpload: any, type: number) {
    const file = event.files[0];
    this.uploadedCustomDesign.push(file);
    this.productInfo.patchValue({ customImage: file });
    this.fm['customImage'].updateValueAndValidity();
    this.readFile(event, type, 0);
    fileUpload.clear();
    this.loadImageFromFile(this.productInfo.value.customImage);
  }

  readFile(event: any, type: number, existing: number) {
    var reader = new FileReader();
    reader.onload = (e: any) => {
      // Set image src
      this.imgProfile = e.target.result;
    }
  };

  removePhoto() {
    this.uploadedCustomDesign = [];
    this.imgProfile = environment.USER_DEFAULT_PIC;
    this.productInfo.patchValue({ customImage: "" });
    this.fm['customImage'].updateValueAndValidity();
  }

  loadImageFromFile(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.imgProfile = reader.result as string;
    };
  }

  addToCart() {
    if (this.productInfo.value.cardType == '' || this.productInfo.value.cardType == undefined) {
      this.toastComp.displayToastMessage("warn", "Warning", this.translate.instant("PRODUCT.ERRORMSG.SELECTCARD"));
      return;
    }

    if (this.productInfo.value.cardType == 'plastic' && this.productInfo.value.cardColor == '') {
      this.toastComp.displayToastMessage("warn", "Warning", this.translate.instant("PRODUCT.ERRORMSG.SELECTCOLOR"));
      return;
    }

    if (this.productInfo.value.licenceType == '' || this.productInfo.value.licenceType == undefined) {
      this.toastComp.displayToastMessage("warn", "Warning", this.translate.instant("PRODUCT.ERRORMSG.SELECTLICENCE"));
      return;
    }

    if (this.productInfo.value.licenceType == 'company' && this.productInfo.value.companyName == '') {
      this.toastComp.displayToastMessage("warn", "Warning", this.translate.instant("PRODUCT.ERRORMSG.SELECTCOMPANY"));
      this.productInfo.setErrors({});
      return;
    }

    if (this.customizeCard == true && this.productInfo.value.customImage == '') {
      this.toastComp.displayToastMessage("warn", "Warning", this.translate.instant("PRODUCT.ERRORMSG.SELECTIMAGE"));
      this.productInfo.setErrors({});
      return;
    }

    if (this.productInfo.invalid) {
      return;
    }

    if (this.authService.isLoggedIn()) {
      this.cartAPI();
    } else {
      this.cookieService.set('cart', JSON.stringify(this.productInfo.getRawValue()), 0, '/');
      this.userLogin = true;
    }
  }

  cardDetails(color: any, selling: any, maxprice: any) {
    this.productInfo.get('cardColor')?.setValue(color);
    this.productInfo.get('sellingPrice')?.setValue(selling);
    this.productInfo.get('maxPrice')?.setValue(maxprice);
  }

  reDirect(value: any) {
    if (value == 'yes') {
      this.router.navigate(["../../auth/login"], {
        queryParams: { slug: this.slug },
      });
    }
    else {
      this.router.navigate(["../../auth/signup"], {
        queryParams: { slug: this.slug },
      });
    }
  }

  cartAPI() {
    const formData = new FormData();
    formData.append("cardType", this.productInfo.value.cardType);
    formData.append("cardColor", this.productInfo.value.cardColor);
    formData.append("licenceType", this.productInfo.value.licenceType);
    formData.append("companyName", this.productInfo.value.companyName);
    formData.append("quantity", this.productInfo.value.quantity);
    formData.append("customImage", this.productInfo.value.customImage);
    formData.append("sellingPrice", this.productInfo.value.sellingPrice);
    formData.append("maxPrice", this.productInfo.value.maxPrice);
    formData.append("slug", this.slug);
    this.webService
      .addToCart(formData)
      .subscribe((result: any) => {
        if (result.status == 200) {
          if (this.slug != '') {
            this.cookieService.delete('cart', '/');
            this.router.navigate(['product/cart'])
          }
          //alert message
          this.displayAlertMessage(true, 'success', 'product/cart', this.translate.instant("PRODUCT.ITEMADDED"));
        }
      }, err => {
        console.log(err);
      });
  }

  displayAlertMessage(visible: boolean, type: string, url: string, message: string) {
    this.alert = { visible: visible, type: type, url: url, message: message };
  }

}
