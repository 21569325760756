import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent {
  @Input() imageFile?: File;
  @Input() type?: number;
  @Input() cropperMinWidth: number = 0;
  @Input() cropperMinHeight: number = 0;
  @Output() croppedImage = new EventEmitter<any>();
  imageChangedEvent: any;
  scale = 1;
  transform: ImageTransform = {};
  maintainAspectRatio: boolean = true;

  ngOnChanges() {
    if (this.imageFile) {
      this.resetImage();
      if (this.type == 1) {
        this.cropperMinWidth = 175;
        this.cropperMinHeight = 145;
        this.maintainAspectRatio = true;
      } else if (this.type == 2) {
        this.cropperMinWidth = 0;
        this.cropperMinHeight = 0;
        this.maintainAspectRatio = false;
      }
      this.readFile(this.imageFile);
    }
  }

  readFile(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.imageChangedEvent = null; // clear previous selection
      setTimeout(() => {
        this.imageChangedEvent = { target: { files: [file] } };
      });
    };
  }

  imageCropped(event: ImageCroppedEvent) {
    const base64Image = event.base64;
    if (base64Image) {
      const byteString = atob(base64Image.split(',')[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: 'image/jpeg' });
      const file = new File([blob], this.imageFile!.name, { type: this.imageFile!.type });
      const objResult = { file: file, type: this.type };
      this.croppedImage.emit(objResult);
    }
  }

  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  resetImage() {
    this.scale = 1;
    this.transform = {};
  }

}
