import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { BehaviorSubject } from 'rxjs';

const API_URL = `${environment.API_URL}`;

@Injectable({
  providedIn: "root",
})
export class LangService {
  Lang: string = "";
  langList: string[] = ["en", "ar"];
  langDBData: any[] = [];
  titlename: string = '';
  private Lang$ = new BehaviorSubject<string>("");
  currentPageSubject: BehaviorSubject<string>;

  constructor(public translate: TranslateService, private http: HttpClient) {
    this.currentPageSubject = new BehaviorSubject<string>("");
    this.setTitle('Cards');
  }

  async loadLanguage() {
    this.translate.addLangs(this.langList);
    await this.http
      .get(`${API_URL}/admin/languages`)
      .toPromise()
      .then((response: any) => {
        if (response.success.code == 200) {
          this.langList = [];
          response.data.languages.forEach((lang: any) => {
            this.langList.push(lang.code.toLowerCase());
          });
          this.translate.addLangs(this.langList);
          this.langDBData = response.data.languages;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  setLang(lang: string) {
    this.Lang = lang;
    this.updateData(lang);
  }

  getLang() {
    return this.Lang;
  }

  getLangDB() {
    return this.langDBData;
  }

  languageList() {
    return this.translate.getLangs();
  }

  changeLang() {
    this.translate.use(this.getLang());
  }

  updateData(value: string) {
    this.Lang$.next(value);
  }

  getData() {
    return this.Lang$.asObservable();
  }

  setTitle(value: any) {
    switch (value) {
      case 'Cards':
        this.titlename = 'MENU.CARDS';
        break;
      case 'Edit-Card':
        this.titlename = 'MENU.EDITCARD';
        break;
      case 'Contacts':
        this.titlename = 'MENU.CONTACTS';
        break;
      case 'Add-Contact':
        this.titlename = 'MENU.ADDCONTACT';
        break;
      case 'Edit-Contact':
        this.titlename = 'MENU.EDITCONTACT';
        break;
      case 'Settings':
        this.titlename = 'MENU.SETTINGS';
        break;
      case 'Logout':
        this.titlename = 'MENU.LOGOUT';
        break;
    }
    this.currentPageSubject.next(this.titlename);
  }

  getTitle() {
    return this.currentPageSubject.value;
  }

}
