import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

const API_URL = `${environment.API_URL}`;

@Injectable({
    providedIn: "root",
})
export class ConfigService {
    appConfig: any;

    constructor(private http: HttpClient) { }

    setAppConfig(data: any) {
        this.appConfig = data;
    }

    getAppConfig() {
        return this.appConfig;
    }

    async loadApplicationConfig() {
        await this.http
            .get(`${API_URL}/setting?type=1`)
            .toPromise()
            .then((response: any) => {
                if (response.success.code == 200) {
                    this.setAppConfig(response.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }
}
