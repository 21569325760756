import { Binary } from '@angular/compiler';

export class otherProduct {
    card_back_image: string = '';
    card_front_image: string = '';
    description: string = '';
    id: string = '';
    material: string = '';
    max_retail_price: string = '';
    name: string = '';
    selling_price: string = '';
    seo_description: string = '';
    seo_keywords: string = '';
    seo_title: string = '';
    short_description: string = '';
    slug: string = '';
    status: string = '';
    tags: string = '';
    type: string = '';
    details: otherProductDetail[] = [];
}
export class otherProductDetail {
    card_type: string = '';
    id: string = '';
    max_retail_price: string = '';
    selling_price: string = '';
}
export class Product {
    card_back_image: string = '';
    card_front_image: string = '';
    description: string = '';
    id: string = '';
    material: string = '';
    max_retail_price: string = '';
    name: string = '';
    selling_price: string = '';
    seo_description: string = '';
    seo_keywords: string = '';
    seo_title: string = '';
    short_description: string = '';
    slug: string = '';
    status: string = '';
    tags: string = '';
    type: string = '';
    details: Detail[] = [];
}
export class Detail {
    card_type: string = '';
    color_detail: colorDetail[] = [];
}
export class colorDetail {
    color: string = '';
    id: string = '';
    max_retail_price: string = '';
    selling_price: string = '';
}
export class order {
    cardType: string = '';
    cardColor: string = '';
    licenceType: string = '';
    companyName: string = '';
    quantity: string = '';
    customImage: string = '';
    sellingPrice: string = '';
    maxPrice: string = '';
}