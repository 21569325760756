import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable, catchError, map, throwError } from "rxjs";
import { AuthService } from './auth/auth.service';
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class MyinterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService, private router: Router) { }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
  }

  getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 401: {
        this.authService.logout(401);
        return `Not Found: ${error.message}`;
      }
      case 404: {
        return `Not Found: ${error.message}`;
      }
      case 403: {
        return `Access Denied: ${error.message}`;
      }
      case 500: {
        return `Internal Server Error: ${error.message}`;
      }
      default: {
        return `Unknown Server Error: ${error.message}`;
      }
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = this.authService.getAuthToken();

    if (token) {
      req = this.addToken(req, token);
    }

    const searchString = '?';
    let updatedURL = '';
    if (req.method === 'GET') {
      if (req.url.indexOf(searchString) >= 0) {
        updatedURL = req.url + '&random=' + Math.random();
      } else {
        updatedURL = req.url + '?random=' + Math.random();
      }
    } else {
      updatedURL = req.url;
    }

    req = req.clone({ url: updatedURL });

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do something with the response
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        let errorMsg: string;
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = this.getServerErrorMessage(error);
        }
        return throwError(error);
      })
    ) as Observable<HttpEvent<any>>;
  }

}
