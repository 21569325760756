import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'contact',
    pure: false
})
export class ContactPipe implements PipeTransform {
    transform(items: any[], fields: string[], value: string): any {
        if (!items || !value) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        // return items.filter(item => item[field].toLowerCase().indexOf(value.toLowerCase()) !== -1);

        return items.filter(item => {
            const lowercaseFilter = value.toLowerCase();

            // Iterate through the columns and check if any column matches the filter value
            for (const column of fields) {
                const lowercaseColumnValue = (item[column]) ? item[column].toLowerCase() : '';

                if (lowercaseColumnValue.includes(lowercaseFilter)) {
                    return true;
                }
            }

            return false;
        });
    }
}