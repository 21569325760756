import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ClipboardService } from 'ngx-clipboard';
import { WebService } from '../../web.service';
import { LangService } from 'src/app/service/lang.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { QRCodeComponent } from 'angularx-qrcode';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/user/user.service';
import { countries } from 'src/app/shared/data/country';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { FileService } from 'src/app/service/file.service';
import { ToastComponent } from 'src/app/shared/components';
import { EMPTY, Subscription } from 'rxjs';
import { SeoService } from 'src/app/service/seo.service';
import { SeoSocialShareService, SeoSocialShareData } from 'ngx-seo';
import { throws } from 'assert';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-card-view',
  templateUrl: './card-view.component.html',
  styleUrls: ['./card-view.component.scss'],
  providers: [MessageService],
})
export class CardViewComponent {
  @ViewChild(QRCodeComponent) qrCodeComponent!: QRCodeComponent;
  @ViewChild(ToastComponent) toastComp!: ToastComponent;
  private subscriptions: Subscription;
  cardView: any;
  cardByIdDetails: any;
  contactInfo: any[] = [];
  mailInfo: any[] = [];
  socialInfo: any[] = [];
  qrCodeValue: string = '';
  personalInfo: any[] = [];
  addressInfo: any[] = [];
  videoInfo: any[] = [];
  titleInfo: any[] = [];
  Lang: string = '';
  errorCode: any;
  vcfName: string = '';
  exchange: FormGroup;
  exchangeContactPopup: boolean = false;
  countryList: any[] = countries;
  qrPopup: boolean = false;
  vcfAddress: string = '';
  headLine: any;
  shareCardPopup: boolean = false;
  code: string = '';
  vcfFirstName: string = '';
  vcfLastName: string = '';
  dir: string = '';
  fileUrl: any;
  shareCont: FormGroup;
  cardColor: string = '';
  addressValue: boolean = false;

  constructor(private route: ActivatedRoute, private router: Router, private webService: WebService, private clipboardService: ClipboardService,
    private userService: UserService, public langser: LangService, private ngxLoader: NgxUiLoaderService, public fileService: FileService,
    public fb: FormBuilder, private http: HttpClient, public translate: TranslateService, public readonly seo: SeoService,
    public readonly seoSocialShareService: SeoSocialShareService, private sanitizer: DomSanitizer) {
    this.Lang = this.langser.getLang();
    this.exchange = this.fb.group({
      firstname: new FormControl("", [Validators.required]),
      lastname: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.email, Validators.minLength(3), Validators.maxLength(320)]),
      // mailType: new FormControl("Work", [Validators.required]),
      mobile: new FormControl('', [Validators.required]),
      // type: new FormControl('Mobile', [Validators.required]),
      // mobileLabel: new FormControl('Work', [Validators.required]),
      companyName: new FormControl('', [Validators.required]),
      designation: new FormControl('', [Validators.required]),
      language: this.Lang,
    });
    this.shareCont = this.fb.group({
      myMail: new FormControl("", [Validators.email]),
      myWhatsApp: new FormControl("", [Validators.required, Validators.pattern('^[0-9]+$')]),
    });
    this.translate.use(this.Lang);
    this.subscriptions = this.langser.getData().subscribe(value => {
      this.Lang = value;
      this.translate.use(this.Lang);
      if (value == 'ar') { this.dir = 'rtl'; }
      else {
        this.dir = 'ltr';
      }
    });
  }

  ngOnInit(): void {
    this.ngxLoader.start();
    this.route.paramMap.subscribe(params => {
      if (params.keys.length > 0 && params.keys[0] == 'id') {
        let cardNo = params.get('id');
        this.getData(cardNo); //Calls the function with card ID as parameter to get data from the relevant card.
      }
    });
  }

  //Function to get card data using API call with card ID as query.
  //On success will return entered details of the card which was requested and on fail will log error in console and slow alert.
  getData(cardNo: any) {
    this.webService.cardByIdWebView(cardNo, this.code)
      .subscribe((result: any) => {
        this.ngxLoader.stop();
        if (result.status == 200) {
          this.cardView = result.body.data;
          this.cardByIdDetails = this.cardView;
          this.contactInfo = [];
          this.mailInfo = [];
          this.socialInfo = [];
          this.qrCodeValue = '';
          this.personalInfo = [];
          this.addressInfo = [];
          this.videoInfo = [];
          this.titleInfo = [];

          if (this.code == "") {
            if (this.cardByIdDetails.default_lang && this.cardByIdDetails.default_lang_status == 1) {
              this.Lang = this.cardByIdDetails.default_lang;
              this.translate.use(this.Lang);
              if (this.Lang == 'ar') { this.dir = 'rtl'; }
              else {
                this.dir = 'ltr';
              }
            }

            else if (this.cardByIdDetails.default_lang_status == 0) {
              this.Lang = (this.cardByIdDetails.default_lang == 'ar' ? 'en' : 'ar');
              this.translate.use(this.Lang);
              if (this.Lang == 'ar') { this.dir = 'rtl'; }
              else {
                this.dir = 'ltr';
              }
            }

          }
          this.cardColor = (this.cardByIdDetails.color == '' || this.cardByIdDetails.color == null || this.cardByIdDetails.color == "null" || this.cardByIdDetails.color == undefined) ? environment.DEFAULT_COLOR : this.cardByIdDetails.color;
          for (let i = 0; i < this.cardByIdDetails.personal.length; i++) {
            this.personalInfo.push(this.cardByIdDetails.personal[i]);
            this.vcfName = this.cardByIdDetails.personal[i].firstName + this.cardByIdDetails.personal[i].lastName;
            this.headLine = this.cardByIdDetails.personal[i].headLine;
            this.vcfFirstName = this.cardByIdDetails.personal[i].firstName;
            this.vcfLastName = this.cardByIdDetails.personal[i].lastName;

          }
          if (this.cardByIdDetails.contact != null || this.cardByIdDetails.contact != undefined) {
            for (let i = 0; i < this.cardByIdDetails.contact.length; i++) {
              this.contactInfo.push(JSON.parse(this.cardByIdDetails.contact[i]));
            }
          }

          // if (this.dir == 'rtl') {
          //   this.contactInfo.forEach(contact => {
          //     const mobileWithoutSpaces = contact.mobile.replace(/\s/g, '');
          //     const reversedString = mobileWithoutSpaces.split(' ').reverse().join('');
          //     const reversedMobile = reversedString.replace(/(\d{1})(?=\d)/g, '$1 ');
          //     contact.mobile = reversedMobile.replace(/\s/g, '');
          //   });

          // }

          if (this.cardByIdDetails.mail != null || this.cardByIdDetails.mail != undefined) {
            for (let i = 0; i < this.cardByIdDetails.mail.length; i++) {
              this.mailInfo.push(JSON.parse(this.cardByIdDetails.mail[i]));
            }
          }


          for (let i = 0; i < this.cardByIdDetails.address.length; i++) {
            this.addressInfo.push(this.cardByIdDetails.address[i]);
          }

          if (this.cardByIdDetails.sociallink != null || this.cardByIdDetails.sociallink != undefined) {
            for (let i = 0; i < this.cardByIdDetails.sociallink.length; i++) {
              this.socialInfo.push(JSON.parse(this.cardByIdDetails.sociallink[i]));
            }


          }
          for (let i = 0; i < this.cardByIdDetails.titleInfo.length; i++) {
            this.titleInfo.push(this.cardByIdDetails.titleInfo[i]);
          }

          if (this.cardByIdDetails.videoInfo != null || this.cardByIdDetails.videoInfo != undefined) {
            for (let i = 0; i < this.cardByIdDetails.videoInfo.length; i++) {
              this.videoInfo.push(this.cardByIdDetails.videoInfo[i]);
            }
          }

          this.qrCodeValue = this.cardByIdDetails.url;

          const seoData: SeoSocialShareData = {
            title: 'MYTAG',
            description: this.headLine,
            url: this.cardByIdDetails.url,
            image: this.cardView.profilePhoto,
            // keywords: '',
            // type: '',
            // author: '',
            // section: '',
            // published: '',
            // modified: ''
          };
          this.seoSocialShareService.setData(seoData);
        }
        else if (result.status == 203) {
          if (result.body.data.card_status == 0) {
            this.toastComp.displayToastMessage("error", "Error", result.body.error.message);
            setTimeout(() => {
              this.router.navigate(['/']);
            }, 2000);
          } else if (result.body.data.is_profile_filled == 1) {
            this.toastComp.displayToastMessage('error', 'Error', result.body.error.message);
            setTimeout(() => {
              this.router.navigate(['/auth/login']);
            }, 2000);
          } else {
            this.router.navigate(['/auth/signup'], {
              queryParams: { cardNo: cardNo },
            });
          }
        }
      }, err => {
        this.ngxLoader.stop();
        console.log(err.error.error.code);
        this.errorCode = err.error.error.code;
      });

  }

  copy(text: string) {
    this.clipboardService.copy(text);
    this.toastComp.displayToastMessage('success', 'Success', this.translate.instant("COMMON.ERRORMSG.LINKCOPIED"));
  }

  downloadVcf() {
    const formData = new FormData();
    formData.append("code", this.langser.getLang());
    formData.append("cardNo", this.cardByIdDetails.card_no);

    this.webService
      .saveContact(formData)
      .subscribe((result: any) => {
        if (result.status == 200) {
          const link = document.createElement('a');
          link.href = result.body.data.vcf_url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          // window.URL.revokeObjectURL(url);
          URL.revokeObjectURL(link.href);


        } else if (result.status == 203) {
          this.toastComp.displayToastMessage("error", "Error", result.body.error.message);
        }
      }, err => {
        this.toastComp.displayToastMessage("error", "Error", err.error.error.message);
      });



  }

  fm() {
    return this.exchange.controls;
  }
  shareFm() {
    return this.shareCont.controls;
  }

  exchangeContact() {
    this.exchangeContactPopup = true;
  }

  qrPopupClose() {
    this.qrPopup = false;
  }

  exchangeCont() {
    if (this.exchange.invalid) {
      this.toastComp.displayToastMessage("error", "Error", this.translate.instant("COMMON.ERRORMSG.MANDATORYFIELDS"));
      return;
    }

    const formData = new FormData();
    let i = 0;
    this.langser.languageList().forEach((lang: any) => {
      let personal = { "language": lang, "firstName": this.exchange.value.firstname, "lastName": this.exchange.value.lastname, "jobTitle": this.exchange.value.designation, "company": this.exchange.value.companyName, "headline": "" };
      formData.append(`personal[${i}]`, JSON.stringify(personal));
      i = i + 1;
    });

    let contact = { "type": "Work", "label": "Mobile", "mobile": this.exchange.value.mobile };
    formData.append(`contact[0]`, JSON.stringify(contact));

    let mail = { "email": this.exchange.value.email, "type": "Work" };
    formData.append(`mail[0]`, JSON.stringify(mail));

    formData.append("language", this.Lang)
    formData.append("card_no", this.cardByIdDetails.card_no);

    this.userService
      .exchangeContact(formData)
      .subscribe((result: any) => {
        if (result.status == 200) {
          this.toastComp.displayToastMessage('success', 'Success', result.body.success.message);
          this.exchangeContactPopup = false;
          // this.resetExchangeValue();
          this.exchange.reset();
        } else if (result.status == 203) {
          this.toastComp.displayToastMessage("error", "Error", result.body.error.message);
          this.exchangeContactPopup = false;
        }
      }, err => {
        this.toastComp.displayToastMessage("error", "Error", err.error.error.message);
        this.exchangeContactPopup = false;
      });
  }

  renderAddress(objAddress: any) {
    let address = '';
    // if (objAddress.doorno && objAddress.doorno != '' && objAddress.doorno != null) {
    //   address += objAddress.doorno ;
    // }
    if (objAddress.address1 && objAddress.address1 != '' && objAddress.address1 != null) {
      address +=  objAddress.address1 + ', ';
    }
    if (objAddress.address2 && objAddress.address2 != '' && objAddress.address2 != null) {
      address +=  objAddress.address2 + ', ' ;
    }
    // if (objAddress.landmark && objAddress.landmark != '' && objAddress.landmark != null) {
    //   address +=  objAddress.landmark ;
    // }
    if (objAddress.city && objAddress.city != '' && objAddress.city != null) {
      address += objAddress.city + ', ';
    }
    if (objAddress.state && objAddress.state != '' && objAddress.state != null) {
      address +=  objAddress.state + ', ';
    }
    if (objAddress.country && objAddress.country != '' && objAddress.country != null && objAddress.country != undefined) {
      const objCountry = objAddress.country;
      address +=  objCountry + ', ';
    }
    if (objAddress.postalcode && objAddress.postalcode != '' && objAddress.postalcode != null) {
      address +=  objAddress.postalcode;
    }
    this.vcfAddress = address;
    if (address != '') {
      this.addressValue = true;
    }
    return (address.replace(/,\s*$/, ''));
  }

  async convertImageToBase64(): Promise<void> {
    try {
      const response = await fetch('https://example.com/image.jpg'); // replace with your image URL
      const blob = await response.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result as string;
        console.log(base64data);
      };
    } catch (error) {
      console.error(error);
    }
  }

  mail() {
    if (this.shareCont.value.myMail == '' || this.shareCont.value.myMail == null || this.shareCont.value.myMail == undefined) {
      this.toastComp.displayToastMessage('error', 'Warning', this.translate.instant("PRODUCT.EMAILEMPTY"));
      return;
    }
    const formData = new FormData();
    formData.append("email", this.shareCont.value.myMail);
    formData.append("cardNo", this.cardByIdDetails.card_no);
    formData.append("code", this.langser.getLang());
    this.ngxLoader.start();
    this.webService.exchangeEmail(formData).subscribe((result: any) => {
      if (result.status == 200) {
        this.toastComp.displayToastMessage('success', 'Success', result.body.success.message);
        this.shareCardPopup=false;
        this.shareCont.get('myMail')?.setValue('');
      }
    }, err => {
      this.toastComp.displayToastMessage("error", "Error", err.error.error.message);
      this.shareCardPopup=false;

    });
  }

  WhatsApp() {
    if (this.shareCont.value.myWhatsApp == '+') {
      this.toastComp.displayToastMessage('Warning', 'Warning', this.translate.instant("WHATSAPP.NOEMPTY"));
      return;
    }
    const encodeHead = encodeURIComponent(this.translate.instant("WHATSAPP.HEAD"));
    const encodedLink = encodeURIComponent(this.cardByIdDetails.url);
    const phoneNumber = this.shareCont.value.myWhatsApp;
    // const whatsappURL = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodedName}%0A${encodedLink}`;
    // this.translate.instant()
    // const whatsappURL = `whatsapp://send?phone=${phoneNumber}&text=${encodedName}%0A${encodedLink}`;
    // const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedName}%0A${encodedLink}`;
    // window.open(whatsappURL, '_blank');
    // window.open(`whatsapp://send?phone=${phoneNumber}`, '_blank');

    const isMobile = this.detectMobile();
    if (isMobile) {
      const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeHead}%0A${encodedLink}`;
      window.location.href = whatsappURL;
    } else {
      const whatsappURL = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeHead}%0A${encodedLink}`;
      window.open(whatsappURL);
    }
    this.shareCont.patchValue({ myWhatsApp: '' });
    this.shareCardPopup = false;

  }

  detectMobile(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor || window.navigator.userAgent;
    return /android|iPad|iPhone|iPod/i.test(userAgent);
  }

  toggle(val: any) {
    this.langser.setLang(val);
    this.translate.use(val);
    this.code = val;
    this.getData(this.cardView.card_no);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  contactLabelConversion(key: string) {
    let key1: string = '';
    switch (key.toLowerCase()) {
      case 'telephone':
        key1 = "CARDADD.GENERAL.TELEPHONE";
        break;
      case 'mobile':
        key1 = "CARDADD.GENERAL.MOBILE";
        break;
      case 'fax':
        key1 = "CARDADD.GENERAL.FAX";
        break;
      case 'work':
        key1 = "CARDADD.GENERAL.WORK";
        break;
      case 'personal':
        key1 = "CARDADD.GENERAL.PERSONAL";
        break;
      case 'office':
        key1 = "CARDADD.GENERAL.OFFICE";
        break;
      case 'main':
        key1 = "CARDADD.GENERAL.MAIN";
        break;
      case 'direct':
        key1 = "CARDADD.GENERAL.DIRECT";
        break;
      case 'stores':
        key1 = "CARDADD.GENERAL.STORES";
        break;
      case 'support':
        key1 = "CARDADD.GENERAL.SUPPORTS";
        break;
      case 'toll free':
        key1 = "CARDADD.GENERAL.TOLLFREE";
        break;
    }
    return (key1 == '') ? '' : this.translate.instant(key1);
  }
  saveContact() {
    const formData = new FormData();
    formData.append("code", this.langser.getLang());
    formData.append("cardNo", this.cardByIdDetails.card_no);
    this.webService
      .saveContact(formData)
      .subscribe((result: any) => {
        if (result.status == 200) {
          this.toastComp.displayToastMessage('success', 'Success', result.body.success.message);
        } else if (result.status == 203) {
          this.toastComp.displayToastMessage("error", "Error", result.body.error.message);
        }
      }, err => {
        this.toastComp.displayToastMessage("error", "Error", err.error.error.message);
      });
  }
  onKeyDown(event: KeyboardEvent) {
    const key = event.key;
    // Allow navigation keys, backspace, delete, and enter
    const allowedKeys = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Backspace', 'Delete', 'Enter'];
    const isNumber = /^[0-9]$/;
    if ((!isNumber.test(key) && !allowedKeys.includes(key)) || (key === ' ')) {
      event.preventDefault();
    }
  }
  reverseNumber(number: number): number {
    const reversedString = number.toString().split('').reverse().join('');
    return parseInt(reversedString);
  }
  resetExchangeValue() {
    this.exchange.get('firstname')?.setValue('');
    this.exchange.get('lastname')?.setValue('');
    this.exchange.get('email')?.setValue('');
    this.exchange.get('mailType')?.setValue('work');
    this.exchange.get('mobile')?.setValue('');
    this.exchange.get('type')?.setValue('work');
    this.exchange.get('companyName')?.setValue('');
  }


}
