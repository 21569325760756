<section class="mx-auto px-4 sm:px-6 lg:px-0 bg-white">
    <section class="lg:container lg:mx-auto py-5 lg:px-8 lg:py-16">
        <h2 class="text-2xl font-semibold mb-3">{{"PRODUCT.OTHERPRODUCTS"|translate}}</h2>
        <section class="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 lg:gap-8 py-5 gap-4">
            <div class="mb-5 px-5" *ngFor="let item of details">
                <figure class="mb-4">
                    <img src="../../../../assets/img_card_type.png" alt="card type" />
                </figure>
                <p class="text-primary mb-2">MYTAG {{item.name}}</p>
                <p class="text-secondary-light mb-2">{{"PRODUCT.CUSTOMIZECARD"|translate}}</p>
                <p class="mb-2"><span class="mr-2"> {{item.selling_price|currency : "KD"}}</span><span
                        class="text-gray-400 mr-2 line-through">
                        {{item.max_retail_price}}</span> <span class="text-primary "></span></p>
                <div class="text-gray-500" [innerHTML]="this.description"></div>
                <button type="submit"
                    class="bg-primary hover:bg-transparent text-white font-semibold hover:text-primary py-2 px-8 border border-primary hover:border-primary rounded"
                    (click)="product(item.slug)">
                    {{"PRODUCT.BUYNOW"|translate}}
                </button>
            </div>

        </section>

    </section>
</section>