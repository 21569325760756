import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  providers: [MessageService],
})
export class ToastComponent {

  constructor(private ngxLoader: NgxUiLoaderService, private messageService: MessageService, private translate: TranslateService) {

  }

  displayToastMessage(type: string, summary: string, message: string) {
    this.ngxLoader.stop();
    const summarKey = (summary == 'Success') ? 'COMMON.LABEL.SUCCESS' : ((summary == 'Error') ? 'COMMON.LABEL.ERROR' : 'COMMON.LABEL.WARNING');
    this.messageService.clear();
    this.messageService.add({
      severity: type,
      summary: this.translate.instant(summarKey),
      detail: message,
    });
  }
}
