import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SocialShareComponent {
  @Input() urlToShare!: string;
  @Input() title!: string;
  // titleToShare = 'Check out this awesome website!';
  // descriptionToShare = 'This website has everything you need!';
  // imageToShare = 'https://example.com/images/share.png';
  // hashtagsToShare = 'example, website';
  // twitterAccountToShare = '@example';
  showButtons = ['linkedin', 'twitter', 'facebook', 'whatsapp', 'email'];
  buttonSize = 'md';
  buttonTheme = 'default';

  shareOn(value: any) {
    const shareUrl = encodeURIComponent(this.urlToShare);
    const title = this.title;
    switch (value) {
      case 'linkedin':
        window.open(`https://www.linkedin.com/shareArticle?url=${shareUrl}&title=${title}`, 'sharer');
        break;
      case 'twitter':
        window.open(`https://twitter.com/intent/tweet?url=${shareUrl}&text=${title}`, 'sharer');
        break;
      case 'facebook':
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, 'sharer');
        break;
      case 'whatsapp':
        const isMobile = this.detectMobile();
        if (isMobile) {
          const whatsappURL = `https://wa.me/?text=${title}%0A${shareUrl}`;
          window.location.href = whatsappURL;
        } else {
          const whatsappURL = `https://web.whatsapp.com/send?&text=${title}%0A${shareUrl}`;
          window.open(whatsappURL);
        }
        break;
      case 'email':
        const mailtoUrl = `mailto:?subject=${title}&body=${shareUrl}`;
        window.location.href = mailtoUrl;
        break;
    }
  }

  detectMobile(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor || window.navigator.userAgent;
    return /android|iPad|iPhone|iPod/i.test(userAgent);
  }
}
