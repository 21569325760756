<p-dialog class="lg:w-[50%] info-popup" [(visible)]="showAlertModal" [modal]="true" [baseZIndex]="10001"
    [draggable]="false" [resizable]="false" (onHide)="closeAlertModal()" [closable]="true">
    <div class="info-msg">
        <img [src]="getIconPath()" class="w-10 h-10">
        <h1 class="text-black">{{getHeading()}}</h1>
        <p class="text-sm text-gray-500">{{message}}</p>
    </div>
    <div class="flex w-full justify-center">
        <button type="button"
            class="bg-white text-primary font-semibold py-2 px-8 border border-primary rounded mr-3 ml-3"
            (click)="closeAlertModal()">{{getBtnText()|translate}}</button>
        <button type="button" class="bg-primary text-white font-semibold py-2 px-8 border border-primary rounded"
            (click)="routeToPage()">{{"COMMON.LABEL.CONTINUE"|translate}}</button>
    </div>
</p-dialog>