import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WebService } from '../../web.service';
import { ProductService } from '../product.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-other-product',
  templateUrl: './other-product.component.html',
  styleUrls: ['./other-product.component.scss']
})
export class OtherProductComponent implements OnInit {
  details: any;
  slug: any;
  cardDetail: any;
  description: SafeHtml | any;

  constructor(private route: ActivatedRoute, private router: Router, private productService: ProductService, private webService: WebService, private sanitizer: DomSanitizer) { }
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.slug = params['slug'];
    });
    this.getCard();


  }
  product(value: any) {
    // this.router.navigate(['/productDetailBySlug/', slug]);
    let url = '/product/' + value;
    // this.router.navigateByUrl(url);
    window.location.href = url;


  }
  getCard() {
    this.webService
      .productDetailBySlug(this.slug)
      .subscribe((result: any) => {
        this.cardDetail = result.body.data;
        this.description = this.sanitizer.bypassSecurityTrustHtml(this.cardDetail.description);
        if (result.status == 200) {
          this.productService.setProduct(this.cardDetail)
          for (let i = 0; i < this.cardDetail.other_products.length; i++) {
            this.productService.setOtherProductDetails(this.cardDetail.other_products[i])

          }
          this.details = this.productService.getOtherProductDetails();
          console.log(this.details);

        } else if (result.status == 203) {

        }
      }, err => {
        console.log(err);
      });


  }

}
