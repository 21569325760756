import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Subscription } from "rxjs";
import { catchError, finalize, first, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ApiService } from "../service/api.service";
import { CookieService } from 'ngx-cookie-service';

import { AuthModel } from "./models/auth.model";
import { UserModel } from "./models/user.model";
import { LangService } from "../service/lang.service";

const API_URL = `${environment.API_URL}`;

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private subscriptions: Subscription[] = [];
    private tokenKey = `${environment.appVersion}-${environment.AUTHDATA_KEY}`;
    private userDataKey = `${environment.appVersion}-${environment.USERDATA_KEY}`;
    currentUserSubject: BehaviorSubject<UserModel>;
    user: UserModel;
    storageType: number;

    constructor(private apiservice: ApiService, private router: Router, private cookieService: CookieService, private langService: LangService) {
        this.user = new UserModel();
        this.currentUserSubject = new BehaviorSubject<UserModel>(this.user);
        this.storageType = environment.STORAGE_TYPE;
    }

    login(formData: any) {
        return this.apiservice.Post(`${API_URL}/login`, formData)
            .pipe(
                map((response: any) => {
                    return response;
                }),
                // catchError(err => {
                //     console.log(err);
                // })
            );
    }

    forgetPassword(formData: any) {
        return this.apiservice.Post(`${API_URL}/reSendOtp`, formData)
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    setNewPassword(formData: any) {
        return this.apiservice.Post(`${API_URL}/forgotPassword`, formData)
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    signUp(formData: any) {
        return this.apiservice.Post(`${API_URL}/signup`, formData)
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    logout(errorCode?: number) {
        if (errorCode == 401) {
            this.removeFromStorage(this.tokenKey);
            this.removeFromStorage(this.userDataKey);
            this.router.navigate(['/auth/login']);
            return;
        }
        const formData = new FormData()
        this.apiservice.Post(`${API_URL}/logout`, formData)
            .subscribe(value => {
                this.removeFromStorage(this.tokenKey);
                this.removeFromStorage(this.userDataKey);
                this.router.navigate(['/auth/login']);
            }, err => {
                if (err.status == 401 || errorCode == 401) {
                    this.removeFromStorage(this.tokenKey);
                    this.removeFromStorage(this.userDataKey);
                    this.router.navigate(['/auth/login']);
                }
            });
    }

    setAuthToSessionStorage(auth: AuthModel): boolean {
        // store auth authToken/refreshToken/epiresIn in session storage to keep user logged in between page refreshes
        if (auth && auth.authToken) {
            this.setToStorage(this.tokenKey, JSON.stringify(auth));
            return true;
        }
        return false;
    }

    private getAuthFromLocalStorage(): AuthModel {
        let authData = new AuthModel;
        try {
            authData.setAuth(this.getFromStorage(this.tokenKey));
        } catch (error) {
            console.error(error);
        }
        return authData;
    }

    getAuthToken() {
        let currentToken: any = this.getFromStorage(this.tokenKey);
        if (currentToken) {
            let accessToken = JSON.parse(currentToken);
            return accessToken.authToken;
        }
        return;
    }

    setUserDataToStorage(user: any) {
        if (user) {
            this.removeFromStorage(this.userDataKey);
            this.setToStorage(this.userDataKey, JSON.stringify(user));
        }
    }

    private getUserDataFromStorage(): UserModel {
        let userData = new UserModel;
        try {
            if (this.getFromStorage(this.userDataKey) != null) {
                userData.setUser(JSON.parse(this.getFromStorage(this.userDataKey) as string));
            }
        } catch (error) {
            console.error(error);
        }
        return userData;
    }

    setAuthInfo(objResult: any) {
        const auth = new AuthModel();
        auth.setAccessToken(objResult.headers.get('access_token'));
        // auth.setAccessToken(result.headers.get('expires_in'));
        this.setAuthToSessionStorage(auth);
    }

    setUserInfo(objResult: any, web: string) {
        this.user = new UserModel();
        this.user.setUser(objResult.body.data);
        this.setUserDataToStorage(objResult.body.data);
        if (this.user) {
            this.currentUserSubject.next(this.user);
            const userLang = (this.user.defaultLanguage) ? this.user.defaultLanguage.toLowerCase() : "en";
            const currentLang = this.langService.getLang();
            if (userLang != '' && userLang != currentLang) {
                this.langService.setLang(userLang);
            }
        } else {
            this.logout();
        }

        if (web != '') {
            this.router.navigate(["product", web]);
        } else {
            this.router.navigate(["/user/card/list"]);
        }
    }

    getUserInfo(): UserModel {
        const sb1 = this.currentUserSubject.pipe()
            .subscribe(user => {
                if (user.id != '') {
                    this.user = Object.assign({}, user);
                } else {
                    this.user = this.getUserDataFromStorage();
                    const userLang = (this.user.defaultLanguage) ? this.user.defaultLanguage.toLowerCase() : "en";
                    this.langService.setLang(userLang);
                }
            });
        this.subscriptions.push(sb1);
        return this.user;
    }

    get currentUserValue(): UserModel {
        return this.currentUserSubject.value;
    }

    set currentUserValue(user: UserModel) {
        this.currentUserSubject.next(user);
    }

    isLoggedIn(): boolean {
        this.user = this.getUserInfo();
        // Check if the user is currently logged in
        return (this.user.id !== '' && this.user.id !== null);
    }

    setProfileInfo(objProfile: any) {
        this.user = this.getUserInfo();
        if (this.user) {
            this.user.firstname = objProfile.firstName;
            this.user.lastname = objProfile.lastName;
            this.user.fullname = objProfile.firstName + ' ' + objProfile.lastName;
            this.user.email = objProfile.email;
            this.user.phone = objProfile.phone;
            this.user.defaultLanguage = objProfile.language;
            this.user.pic = objProfile.profilePhoto;
            this.setUserDataToStorage(this.user);
            this.currentUserSubject.next(this.user);
        }
    }

    setCardCountInfo(objCardCount: any) {
        this.user = this.getUserInfo();
        if (this.user) {
            this.user.totalCards = objCardCount.total_count;
            this.user.pendingCards = objCardCount.pending_count;
            this.setUserDataToStorage(this.user);
            this.currentUserSubject.next(this.user);
        }
    }

    setToStorage(key: string, value: any) {
        switch (this.storageType) {
            case 1:
                this.cookieService.set(key, value, undefined, '/');
                break;
            case 2:
                localStorage.setItem(key, value);
                break;
            case 3:
                sessionStorage.setItem(key, value);
                break;
        }
    }

    getFromStorage(key: string) {
        let value;
        switch (this.storageType) {
            case 1:
                value = this.cookieService.get(key) ? this.cookieService.get(key) : null;
                break;
            case 2:
                value = localStorage.getItem(key) ? localStorage.getItem(key) : null;
                break;
            case 3:
                value = sessionStorage.getItem(key) ? sessionStorage.getItem(key) : null;
                break;
        }
        return value;
    }

    removeFromStorage(key: string) {
        switch (this.storageType) {
            case 1:
                this.cookieService.delete(key, '/');
                break;
            case 2:
                localStorage.removeItem(key);
                break;
            case 3:
                sessionStorage.removeItem(key);
                break;
        }
    }
}