<!-- <select #langSelect (change)="changeLang(langSelect.value)"
    class="bg-white p-2 px-4 border border-gray-400 rounded-sm uppercase">
    <option *ngFor="let lang of langser.languageList()" [value]="lang" [selected]="lang === translate.currentLang">
        {{ lang }}
    </option>
</select> -->
<div
    class="inline-flex w-full justify-center gap-x-1.5 bg-transparent px-5 py-2 text-sm font-semibold text-blue-700 p-2 px-4">
    <form [formGroup]="langForm">
        <p-selectButton [options]="stateOptions" formControlName="lang" (click)="changeLang(this.langForm.value.lang)">
            <ng-template let-item>
                <span>{{item.toUpperCase()}}</span>
            </ng-template>
        </p-selectButton>
    </form>
</div>