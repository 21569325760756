import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-youtube-player',
  templateUrl: './youtube-player.component.html',
  styleUrls: ['./youtube-player.component.scss']
})
export class YoutubePlayerComponent {
  @Input() videoUrl: string = '';
  @Input() width: number = 300;
  @Input() height: number = 200;

  constructor() { }

  onPlayerReady(event: any) {
    console.log('Player ready', event);
  }

  onPlayerStateChange(event: any) {
    console.log('Player state change', event);
  }

  get videoId() {
    // Parse the video ID from the URL using a regular expression
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const defaultID = '';
    if (this.videoUrl != '' && this.videoUrl != null) {
      const match = this.videoUrl.match(regExp);

      if (match && match[7].length == 11) {
        return match[7];
      }
    }
    return defaultID;
  }
}
