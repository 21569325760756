import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from 'src/app/service/lang.service';
import { Subscription } from "rxjs";
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  dir: string = '';
  private subscriptions: Subscription;

  constructor(public translate: TranslateService, public langser: LangService, public router: Router,private ngxLoader: NgxUiLoaderService) {
    this.subscriptions = this.langser.getData().subscribe(value => {
      this.setValue(value);
    });
  }
  ngOnInit(): void {
    const lang = this.langser.getLang();
    this.setValue(lang);
    this.ngxLoader.stop();

  }
  setValue(lang: string) {
    this.translate.use(lang);
    if (lang == 'en') {
      this.dir = 'ltr';
    }
    if (lang == 'ar') {
      this.dir = 'rtl';
    }
  }
  Home() {
    this.router.navigate(['../home']);
    document.getElementById("home")?.scrollIntoView({ behavior: "smooth" });
  }

  Product() {
    this.router.navigate(['../home'], { fragment: "cardtype" });
    document.getElementById("cardtype")?.scrollIntoView({ behavior: "smooth" });
  }

  Features() {
    this.router.navigate(['../home'], { fragment: "features" });
    document.getElementById("features")?.scrollIntoView({ behavior: "smooth" });
  }

  Demo() {
    this.router.navigate(['../home'], { fragment: "demo" });
    document.getElementById("demo")?.scrollIntoView({ behavior: "smooth" });
  }

  Contactus() {
    this.router.navigate(['../home'], { fragment: "contactus" });
    document
      .getElementById("contactus")
      ?.scrollIntoView({ behavior: "smooth" });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
