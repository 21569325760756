import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from 'src/app/service/lang.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {
  langForm: FormGroup;
  private subscriptions: Subscription;
  stateOptions: any[] = [];

  constructor(public translate: TranslateService, public langser: LangService, public fb: FormBuilder) {
    this.langForm = this.fb.group({
      lang: new FormControl('en')
    });
    this.stateOptions = this.langser.languageList();
    this.subscriptions = this.langser.getData().subscribe(value => {
      this.setValue(value);
    });
  }

  ngOnInit(): void {
    const lang = this.langser.getLang();
    this.setValue(lang);
  }

  setValue(lang: string) {
    this.translate.use(lang);
    this.langForm.patchValue({ lang: lang });
  }

  changeLang(lang: string) {
    this.langser.setLang(lang);
    this.translate.use(lang);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}

