import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ConfigService } from "./config.service";

const API_URL = `${environment.API_URL}`;

@Injectable({
    providedIn: "root",
})
export class FileService {
    appConfig: any;

    constructor(private configService: ConfigService) {
        this.appConfig = this.configService.getAppConfig();
    }

    validFileType(file: any) {
        const acceptFileTypes = /^image\/(gif|jpe?g|png)$/i;
        let result = { message: '', error: false };

        if (file.type.length && !acceptFileTypes.test(file.type)) {
            result.error = true;
            result.message = 'Selected file "' + file.name + '" is not allowed. Supports only JPG, JPEG, PNG.';
        }
        return result;
    }

    validFileSize(file: any) {
        const fileSize: number = file.size; // you will get the uploaded file size
        const imgMaxSize: number = (this.appConfig.max_image_size * 1024 * 1024);  //Convert into bytes
        let result = { message: '', error: false };

        if (fileSize > imgMaxSize) {
            result.error = true;
            // logic goes here for file uploaded greater than 1KB
            result.message = 'Selected file "' + file.name + '" is too big, ' + (fileSize / 1024 / 1024).toFixed(2) + ' MB. File should be smaller than ' + (imgMaxSize / 1024 / 1024).toFixed(0) + ' MB.';
        }
        return result;
    }

    validFileResolution(file: any, type: number): Promise<{ error: boolean, message: string }> {
        return new Promise((resolve, reject) => {
            let result = { message: '', error: false };
            const reader = new FileReader();
            reader.onload = (event: any) => {
                const img = new Image();
                img.onload = () => {
                    console.log(`Image width: ${img.width}, height: ${img.height}`);
                    const imgWidth = img.width;
                    const imgHeight = img.height;
                    if (type == 1 && (imgWidth > 175 || imgHeight > 145)) {
                        result.error = true;
                        // logic goes here for file resolution not match
                        result.message = 'Selected file "' + file.name + '" resolution is too big, ' + imgWidth + '*' + imgHeight + '. File resolution should be 175 * 145';
                    } else if (type == 2 && (imgWidth > 65 || imgHeight > 65)) {
                        result.error = true;
                        // logic goes here for file resolution not match
                        result.message = 'Selected file "' + file.name + '" resolution is too big, ' + imgWidth + '*' + imgHeight + '. File resolution should be 65 * 65';
                    }
                    resolve(result);
                };
                img.onerror = reject;
                img.src = event.target.result;
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }

    setDefaultImage(image: string, type: string, resolution?: number) {
        let imagePath = '';
        if (image == '') {
            if (type == 'user') {
                if (resolution == 150) {
                    imagePath = environment.USER_DEFAULT_PIC_150;
                } else {
                    imagePath = environment.USER_DEFAULT_PIC;
                }
            } else if (type == 'company') {
                imagePath = environment.COMPANY_DEFAULT_PIC;
            }
        } else {
            imagePath = image;
        }
        return imagePath;
    }
}
