import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ClipboardService } from 'ngx-clipboard';
import { WebService } from '../../web.service';
import { LangService } from 'src/app/service/lang.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { QRCodeComponent } from 'angularx-qrcode';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { validatePhoneNumber } from 'src/app/shared/util/phone.validation';
import { countries } from 'src/app/shared/data/country';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { FileService } from 'src/app/service/file.service';
import { ToastComponent } from 'src/app/shared/components';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cardshare',
  templateUrl: './cardshare.component.html',
  styleUrls: ['./cardshare.component.scss'],
  providers: [MessageService],
})
export class CardshareComponent {
  @ViewChild(QRCodeComponent) qrCodeComponent!: QRCodeComponent;
  @ViewChild(ToastComponent) toastComp!: ToastComponent;
  private subscriptions: Subscription;
  cardView: any;
  cardByIdDetails: any;
  contactInfo: any[] = [];
  mailInfo: any[] = [];
  socialInfo: any[] = [];
  qrCodeValue: string = '';
  personalInfo: any[] = [];
  addressInfo: any[] = [];
  Lang: string = '';
  errorCode: any;
  vcfName: string = '';
  exchange: FormGroup;
  exchangeContactPopup: boolean = false;
  countryList: any[] = countries;
  qrPopup: boolean = false;
  myMail: string = '';

  constructor(private route: ActivatedRoute, private router: Router, private webService: WebService, private clipboardService: ClipboardService,
    private langser: LangService, private ngxLoader: NgxUiLoaderService, public fb: FormBuilder, public translate: TranslateService,
    public fileService: FileService) {
    this.Lang = this.langser.getLang();
    this.exchange = this.fb.group({
      firstname: new FormControl("", [Validators.required]),
      lastname: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.email, Validators.minLength(3), Validators.maxLength(320)]),
      countryCode: new FormControl('', [Validators.required]),
      mobile: new FormControl('', [Validators.required]),
      language: this.Lang,
    });

    // Set up the custom validator for phone number input
    const countryCodeControl = this.exchange.get('countryCode') as FormControl;
    const phoneControl = this.exchange.get('mobile') as FormControl;

    // Subscribe to value changes of the country code form control
    countryCodeControl.valueChanges.subscribe(() => {
      phoneControl.setValidators(Validators.required);
      phoneControl.setValidators(validatePhoneNumber(countryCodeControl));
      phoneControl.updateValueAndValidity();
    });
    this.translate.use(this.Lang);
    this.subscriptions = this.langser.getData().subscribe(value => {
      this.Lang = value;
      this.translate.use(this.Lang);
    });
  }

  ngOnInit(): void {
    this.ngxLoader.start();
    this.route.paramMap.subscribe(params => {
      if (params.keys.length > 0 && params.keys[0] == 'id') {
        let cardNo = params.get('id');
        this.getData(cardNo); //Calls the function with card ID as parameter to get data from the relevant card.
      }
    });
  }

  //Function to get card data using API call with card ID as query.
  //On success will return entered details of the card which was requested and on fail will log error in console and slow alert.
  getData(cardNo: any) {
    this.webService.cardByIdWebView(cardNo, this.langser.getLang())
      .subscribe((result: any) => {
        this.ngxLoader.stop();
        if (result.status == 200) {
          this.cardView = result.body.data;
          this.cardByIdDetails = this.cardView;
          let personallength = this.cardByIdDetails.personal.length;
          this.cardByIdDetails.color = (this.cardByIdDetails.color == '' || this.cardByIdDetails.color == null || this.cardByIdDetails.color == "null") ? environment.DEFAULT_COLOR : this.cardByIdDetails.color;
          for (let i = 0; i < personallength; i++) {
            this.personalInfo.push(this.cardByIdDetails.personal[i]);

            if (this.cardByIdDetails.personal[i].language == 'en') {
              this.vcfName = this.cardByIdDetails.personal[i].firstName + this.cardByIdDetails.personal[i].lastName;
            }
            else if (this.cardByIdDetails.personal[i].language == 'ar') {
              this.vcfName = this.cardByIdDetails.personal[i].firstName + this.cardByIdDetails.personal[i].lastName;
            }
          }
          let contactlength = this.cardByIdDetails.contact.length;
          for (let i = 0; i < contactlength; i++) {
            this.contactInfo.push(JSON.parse(this.cardByIdDetails.contact[i]));

          }
          let maillength = this.cardByIdDetails.mail.length;
          for (let i = 0; i < maillength; i++) {
            this.mailInfo.push(JSON.parse(this.cardByIdDetails.mail[i]));
          }
          if (this.cardByIdDetails.sociallink != null || this.cardByIdDetails.sociallink != undefined) {
            let sociallength = this.cardByIdDetails.sociallink.length;
            for (let i = 0; i < sociallength; i++) {
              this.socialInfo.push(JSON.parse(this.cardByIdDetails.sociallink[i]));
            }
          }
          let addrlength = this.cardByIdDetails.address.length;
          for (let i = 0; i < addrlength; i++) {
            this.addressInfo.push(this.cardByIdDetails.address[i]);
          }
          this.qrCodeValue = this.cardByIdDetails.url;


        }
        else if (result.status == 203) {
          this.router.navigate(['/auth/signup'], {
            queryParams: { cardNo: cardNo },
          });
        }
      }, err => {
        this.ngxLoader.stop();
        console.log(err.error.error.code);
        this.errorCode = err.error.error.code;
      });
  }

  copy(text: string) {
    this.clipboardService.copy(text);
    this.toastComp.displayToastMessage('success', 'Success', this.translate.instant("COMMON.ERRORMSG.LINKCOPIED"));
  }

  // downloadVcf() {
  //   const name = this.vcfName;
  //   const phone: { type: string, mobile: string }[] = [];
  //   const email: { email: string }[] = [];
  //   const socialUrl: { url: string }[] = []
  //   for (let i = 0; i < this.contactInfo.length; i++) {
  //     phone.push({ type: this.contactInfo[i].type, mobile: this.contactInfo[i].mobile.number });
  //   }
  //   for (let i = 0; i < this.mailInfo.length; i++) {
  //     email.push({ email: this.mailInfo[i].email });
  //   }
  //   let sociallenght = this.cardByIdDetails.sociallink.length;
  //   for (let i = 0; i < sociallenght; i++) {
  //     socialUrl.push(JSON.parse(this.cardByIdDetails.sociallink[i]));
  //   }
  //   const vcfContent = this.webService.generateVcfContent(name, phone, email, socialUrl);
  //   const blob = new Blob([vcfContent], { type: 'text/x-vcard;charset=utf-8' });
  //   const url = window.URL.createObjectURL(blob);
  //   const vcfName = name + '.vcf';

  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.download = vcfName;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  //   window.URL.revokeObjectURL(url);
  // }

  fm() {
    return this.exchange.controls;
  }

  exchangeContact() {
    this.exchangeContactPopup = true;
  }
  shareButton() {
    // this.qrPopup = true;
    this.route.paramMap.subscribe(params => {
      if (params.keys.length > 0 && params.keys[0] == 'id') {
        let cardNo = params.get('id');
        this.router.navigate(['/share', cardNo]);
      }
    });

  }

  qrPopupClose() {
    this.qrPopup = false;
  }

  exchangeCont() {
    if (this.exchange.invalid) {
      this.toastComp.displayToastMessage("error", "Error", this.translate.instant("COMMON.ERRORMSG.MANDATORYFIELDS"));
      return;
    }

    const formData = new FormData();

    let i = 0;
    this.langser.languageList().forEach((lang: any) => {
      let personal = { "language": lang, "firstName": this.exchange.value.firstname, "lastName": this.exchange.value.lastname, "jobTitle": "", "company": "", "headline": "" };
      formData.append(`personal[${i}]`, JSON.stringify(personal));
      i = i + 1;
    });

    let contact = { "type": "Work", "mobile": this.exchange.value.countryCode + '_' + this.exchange.value.mobile };
    formData.append(`contact[0]`, JSON.stringify(contact));

    let mail = { "email": this.exchange.value.email };
    formData.append(`mail[0]`, JSON.stringify(mail));

    formData.append("language", this.Lang)
    formData.append("card_no", this.cardByIdDetails.card_no);

    this.webService
      .exchangeContact(formData)
      .subscribe((result: any) => {
        if (result.status == 200) {
          this.toastComp.displayToastMessage('success', 'Success', result.body.success.message);
          this.exchangeContactPopup = false;
        } else if (result.status == 203) {
          this.toastComp.displayToastMessage("error", "Error", result.body.error.message);
        }
      }, err => {
        this.toastComp.displayToastMessage("error", "Error", err.error.error.message);
      });
  }

  mail() {
    const formData = new FormData();
    formData.append("email", this.myMail);
    formData.append("cardNo", this.cardByIdDetails.card_no);
    this.ngxLoader.start();
    this.webService.exchangeEmail(formData).subscribe((result: any) => {
      if (result.status == 200) {
        this.toastComp.displayToastMessage('success', 'Success', result.body.success.message);
      }
    }, err => {
      this.toastComp.displayToastMessage("error", "Error", err.error.error.message);
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
