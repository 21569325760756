import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({ name: 'safeUrl' })
export class SafeUrlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }

    transform(url: string): SafeResourceUrl {
        let hrefAttr: string;
        if (!this.protocolExists(url)) {
            hrefAttr = location.protocol + "//" + url;
        } else {
            hrefAttr = url;
        }
        return this.sanitizer.bypassSecurityTrustResourceUrl(hrefAttr);
    }

    protocolExists(link: string) {
        if (link.indexOf("http://") == 0 || link.indexOf("https://") == 0) {
            return true;
        }
        return false;
    }
}
