import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION } from 'ngx-ui-loader';

import { AppRoutingModule } from "./app-routing.module";

import { AppComponent } from "./app.component";
import { SharedModule } from "./shared/shared.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CardViewComponent } from './web/card/card-view/card-view.component';
import { ConfigService } from "./service/config.service";
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { MyinterceptorService } from './myinterceptor.service';
import { ClipboardModule } from 'ngx-clipboard';
import { LangService } from "./service/lang.service";
import { SharedWebModule } from './web/shared/shared.web.module';
import { CardshareComponent } from './web/card/cardshare/cardshare.component';
import { TranslateService, TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'rgba(12,80,219,0.98)',
  bgsOpacity: 1,
  bgsPosition: POSITION.centerCenter,
  bgsSize: 40,
  bgsType: SPINNER.ballSpinClockwise,
  fgsSize: 40,
  fgsColor: '#2494d8',
  fgsPosition: POSITION.centerCenter,
  text: 'Loading...',
};
@NgModule({
  declarations: [AppComponent, CardViewComponent, CardshareComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    BrowserAnimationsModule,
    ClipboardModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    SharedModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    SharedWebModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [TranslateService, HttpClient, LangService],
      useFactory: appInitializerFactory,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [ConfigService, LangService, TranslateService],
      useFactory: (configService: ConfigService, langService: LangService) => {
        return () => {
          //Make sure to return a promise!
          return configService.loadApplicationConfig().then(() => {
            return langService.loadLanguage();
          });
        };
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyinterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

export function translateLoaderFactory(httpClient: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

export function appInitializerFactory(translate: TranslateService, http: HttpClient, langservice: LangService) {
  return () => {
    return new Promise<void>((resolve) => {
      translate.addLangs(['en', 'ar']); // Add supported languages
      translate.setDefaultLang('en'); // Set default language
      langservice.setLang('en');
      // const browserLang:any = translate.getBrowserLang();
      const browserLang: any = 'en';
      translate.use('en');
      resolve();
    });
  };
}