import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { response } from 'express';
import { map } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { ApiService } from "../service/api.service";
import { LangService } from '../service/lang.service';




const API_URL = `${environment.API_URL}`;

@Injectable({
  providedIn: 'root'
})
export class WebService {


  constructor(private apiservice: ApiService, private router: Router, private langser: LangService) { }


  cardByIdWebView(value: any, code: any) {
    return this.apiservice.Get(`${API_URL}/cardView` + '/' + value + '?code=' + code)
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }
  productDetailBySlug(value: any) {
    return this.apiservice.Get(`${API_URL}/productDetailBySlug` + '/' + value)
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }
  productList() {
    return this.apiservice.Get(`${API_URL}/productList`)
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }
  addToCart(value: any) {
    return this.apiservice.Post(`${API_URL}/addToCart`, value)
      .pipe(
        map((response: any) => {
          return response;
        }),
        // catchError(err => {
        //     console.log(err);
        // })
      );
  }
  getAddtoCartList() {
    return this.apiservice.Get(`${API_URL}/getAddToCartList`)
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }
  removeItem(value: any) {
    return this.apiservice.Post(`${API_URL}/deleteCartProduct`, value)
      .pipe(
        map((response: any) => {
          return response;
        }))
  }
  exchangeEmail(value: any) {
    return this.apiservice.Post(`${API_URL}/exchangeContactEmail`, value)
      .pipe(
        map((response: any) => {
          return response;
        }))
  }
  exchangeContact(formData: any) {
    return this.apiservice.Post(`${API_URL}/contacts`, formData)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  getShippingDetails() {
    return this.apiservice.Get(`${API_URL}/getCheckOutAddress`)
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }
  vcfDownload(value: any, code: any) {
    return this.apiservice.Get(`${API_URL}/downloadVCard` + '?cardNo=' + value + '&code=' + code)
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }
  generateVcfContent(name: string, id: number, phone: any[], email: any[], org: string, title: string, address: string, headLine: any, img: any, card: any, fn: string, ln: string): string {

    // Encode the photo as a Base64 string
    const photo = `PHOTO;ENCODING=b;TYPE=JPEG:${img}`;

    // Format the phone numbers
    const phoneEntries = phone.map(p => `TEL;TYPE=${p.type}:${p.mobile}`).join('\n');

    // Format the email addresses
    const emailEntries = email.map(e => `EMAIL;TYPE=${e.type}:${e.email}`).join('\n');

    // Format the URLs
    // const urlEntries = url.map(u => `URL;TYPE=${u.type}:${u.url}`).join('\n');

    // Format the address
    const addressEntry = `ADR;TYPE=WORK:;;${address}`;

    // Add the CARDLINK field
    // const cardLinkEntry =(`CARDLINK:${card}`) + (`\n`) + (`${headLine}`);


    // Format the vCard content
    const vcfContent = `BEGIN:VCARD
VERSION:3.0
UID:${id}
FN:${name}
${phoneEntries}
${emailEntries}
${addressEntry}
URL;CARDLINK:${card}
ORG:${org}
TITLE:${title}
${photo}
NOTE:${headLine}
N:${ln};${fn};;;
END:VCARD`;

    return vcfContent;
  }
  saveContact(formData: any) {
    return this.apiservice.Post(`${API_URL}/saveContact`, formData)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
