export const environment = {
  production: false,
  API_URL: "https://dapi.mytag.ae/api/v1",
  WEB_URL: "https://dapp.mytag.ae/",
  appVersion: 'v726demo1',
  AUTHDATA_KEY: 'authf649fc9a5f55',
  USERDATA_KEY: 'userf649fc9a5f55',
  USER_DEFAULT_PIC: './assets/media/users/default.jpg',
  USER_DEFAULT_PIC_150: './assets/media/users/default_150x150.png',
  COMPANY_DEFAULT_PIC: './assets/media/company/default.png',
  DEFAULT_COLOR: '#000000',
  STORAGE_TYPE: 1,  // 1- Cookies, 2 - LocalStorage, 3 - SessionStorage
};
